<template>
  <div style="height:100% overflow:hidden position: relative;">
    <div>
      <div class="testCahatgpt">
        <div
          class="testCahatgptShow"
          @click="testCahatgptShow = !testCahatgptShow"
        >
          <img
            src="../../assets/img/Stow.webp"
            alt="展开教学助手"
            title="展开教学助手"
          />
          <!-- <img   src="../../assets/img/Stow.webp" alt="展开教学助手"> -->
        </div>
        <testCahatgpt
          style="width: 300px;"
          v-if="testCahatgptShow"
        ></testCahatgpt>
      </div>
    </div>
    <BaseTable
      @changeSelection="JobList"
      @changeIndex="changeIndex"
      :tableData="JobListData"
      :total="total"
      :currectIndex="currectIndex"
      :tableColumn="
        $store.state.teachlanguagedata.page == '页面'
          ? tableColumn
          : tableColumn1
      "
    >
      <!-- 名称 -->
      <template v-slot:name="{ row }">
        <div
          class=""
          style="display: flex;align-items: center;"
          @click="
            go_to(row.id, row.beginTime, row.endTime, 'ModifyTheTitle', row)
          "
        >
          <span v-if="row.planKind == '作业'">
            <img
              v-if="$route.path == '/Class'"
              src="http://anyid-tupian.oss-cn-shanghai.aliyuncs.com/icon/before_class.png"
              width="48"
              height="48"
            />
            <img
              v-else-if="$route.path == '/InClass'"
              src="http://anyid-tupian.oss-cn-shanghai.aliyuncs.com/icon/teaching_class.png"
              width="48"
              height="48"
            />
            <img
              v-else-if="$route.path == '/afterClass'"
              src="http://anyid-tupian.oss-cn-shanghai.aliyuncs.com/icon/after_class.png"
              width="48"
              height="48"
            />
          </span>
          <span v-else>
            <img src="../../assets/img/ceyan.png" width="48" height="48" />
          </span>
          <el-popover placement="bottom" width="200" trigger="hover">
            <span>{{ row.name }}</span>
            <div slot="reference">
              <span
                style="margin-left: 8px;white-space: nowrap;overflow: hidden;"
                >{{ row.name }}</span
              >
            </div>
          </el-popover>
        </div>
      </template>
      <!-- 开始时间 -->
      <template v-slot:beginTime="{ row }">
        <el-date-picker
          style="position: absolute;opacity: 0;"
          v-model="start_time"
          :disabled="row.roomId ? true : false"
          @change="updatebaseInfo(row, start_time, 'beginTime')"
          type="datetime"
          :placeholder="$store.state.teachlanguagedata.select"
          format="yyyy-MM-dd HH:mm:ss"
          value-format="timestamp"
        />
        <div>{{ row.beginTimeNo | formatDate }}</div>
      </template>
      <!-- 结束时间 -->
      <template v-slot:endTime="{ row }">
        <el-date-picker
          style="position: absolute;opacity: 0;"
          v-model="end_time"
          :disabled="row.roomId ? true : false"
          @change="updatebaseInfo(row, end_time, 'endTime')"
          type="datetime"
          :placeholder="$store.state.teachlanguagedata.select"
          format="yyyy-MM-dd HH:mm:ss"
          :picker-options="pickerOptions"
          value-format="timestamp"
        />
        <div>{{ row.endTimeNo | formatDate }}</div>
      </template>
      <!-- 进度条 -->
      <template v-slot:progress="{ row }">
        <el-popover placement="bottom" width="200" trigger="click">
          <div slot="reference">
            <el-progress :percentage="parseFloat(Math.round(row.progress))">
            </el-progress>
          </div>
        </el-popover>
      </template>
      <template v-slot:status="{ row }">
        <div>
          {{ row.status == '未完成' ? '未完成' : '已完成' }}
        </div>
      </template>
      <!-- 操作 -->
      <template v-slot:caozuo="{ row }">
        <div class="" style="white-space: nowrap;overflow: auto;">
          <span
            style="margin-right: 10px;"
            v-if="row.planKind == '测验' && row.status == '进行中'"
          >
            <a
              v-preventReClick
              @click="
                go_to(row.id, row.beginTime, row.endTime, 'Explain', row, true)
              "
            >
              <span>{{ $store.state.teachlanguagedata.test }}</span>
            </a>
          </span>
          <span>
            <span
              v-preventReClick
              v-if="row.planKind == '作业' && row.stageKind == '课中'"
              style="margin-right: 10px;"
              ><a
                @click="
                  go_to2(row.id, row.beginTime, row.endTime, 'Explain', row)
                "
                >{{ $store.state.teachlanguagedata.explain }}</a
              >
            </span>
            <span v-else style="margin-right: 10px;">
              <a
                v-preventReClick
                @click="
                  go_to(row.id, row.beginTime, row.endTime, 'Explain', row)
                "
              >
                <span>{{ $store.state.teachlanguagedata.explain }}</span>
              </a>
            </span>
          </span>
          <!-- <span style="margin-right: 10px;" id="report_type">
            <a
              v-preventReClick
              @click="go_to(row.id, row.beginTime, row.endTime, 'Study', row)"
              >报告</a
            >
          </span> -->
          <!-- <span style="margin-right: 10px;">
            <a
              v-preventReClick
              @click="go_to(row.id, row.beginTime, row.endTime, 'Correct', row)"
              >{{ $store.state.teachlanguagedata.corrections }}</a
            >
          </span> -->
          <!-- <span style="margin-right: 10px;">
            <a v-preventReClick @click="ButtonMiddle(row, '2')">下载</a>
          </span> -->
          <span style="margin-right: 10px;" v-if="false">
            <a v-preventReClick @click="stepQuestions(row)">分层推题</a>
          </span>
        </div>
      </template>
    </BaseTable>
    <el-dialog
      :title="$store.state.teachlanguagedata.choosetheanswermethod"
      :visible.sync="dialogVisible"
      width="30%"
    >
      <el-select
        clearable
        filterable
        v-model="answertype"
        :placeholder="$store.state.teachlanguagedata.choosetheanswermethod"
      >
        <el-option
          v-for="item in ['铺码作业', '作业本']"
          :key="item"
          :label="item"
          :value="item"
        >
        </el-option>
      </el-select>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">{{
          $store.state.teachlanguagedata.cancel
        }}</el-button>
        <el-button
          type="primary"
          @click="
            go_to(go_todata1, go_todata2, go_todata3, go_todata4, go_todata5)
          "
        >
          {{ $store.state.teachlanguagedata.confirm }}</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>

<script>
import testCahatgpt from '../../components/Ceshi/testChatgpt.vue';
import { mapMutations } from 'vuex';
import { getPageWorks, getsetId } from '../../api/class.js';
import {
  queryPlanById,
  updateBaseInfo,
  queryQuestionListBySetDegreeTactics,
} from '../../api/base.js';
import BaseTable from '../../components/BasicComponents/BaseTable.vue';
export default {
  data() {
    return {
      successForIn: false, //查看本地是否找到currectIndex ,找到就不请求接口了
      testCahatgptShow: false,
      dialogVisible: false, //课中作业弹出
      answertype: '铺码作业',
      planKind: null, // 测验作业
      stageKind: null, // 课前课中课后
      chapterIds: null,
      userId: null, //学生Id
      teachId: null, //学生Id
      classId: null, //班级id
      start_time: null, //开始时间
      end_time: null, //结束时间
      pickerOptions: {
        disabledDate(time) {
          return Date.now() - 8.64e7 >= time.getTime();
        },
      },
      currectIndex: 1,
      pageTotal: 10,
      total:
        this.$route.path == '/InClass'
          ? 812
          : this.$route.path == '/Class'
          ? 332
          : 265,
      JobListData: [], //返回list  列表
      tableColumn: [
        {
          prop: 'name',
          label: '名称',
          slot: true,
          slotName: 'name',
          width: 400,
        },
        {
          prop: 'beginTime',
          label: '开始时间',
          slot: true,
          slotName: 'beginTime',
        },
        {
          prop: 'endTime',
          label: '结束时间',
          slot: true,
          slotName: 'endTime',
        },
        {
          prop: 'progress',
          label: '提交进度',
          slot: true,
          slotName: 'progress',
        },
        {
          prop: 'status',
          label: '状态',
          slot: true,
          slotName: 'status',
        },
        {
          prop: 'status',
          label: '操作',
          slot: true,
          slotName: 'caozuo',
        },
      ],
      tableColumn1: [
        {
          prop: 'name',
          label: 'JobName',
          slot: true,
          slotName: 'name',
        },
        {
          prop: 'beginTime',
          label: 'BeginTime',
          slot: true,
          slotName: 'beginTime',
        },
        {
          prop: 'endTime',
          label: 'EndTime',
          slot: true,
          slotName: 'endTime',
        },
        {
          prop: 'progress',
          label: 'Submit Progress',
          slot: true,
          slotName: 'progress',
        },
        {
          prop: 'status',
          label: 'Status',
          slot: true,
          slotName: 'status',
        },
        {
          prop: 'status',
          label: 'Operation',
          slot: true,
          slotName: 'caozuo',
        },
      ],
    };
  },
  components: {
    BaseTable,
    testCahatgpt,
  },
  filters: {
    //配置过滤器
    formatDate: function(value) {
      //调用时间戳为日期显示
      let date = new Date(value);
      let y = date.getFullYear(); //获取年份
      let m = date.getMonth() + 1; //获取月份
      m = m < 10 ? '0' + m : m; //月份不满10天显示前加0
      let d = date.getDate(); //获取日期
      d = d < 10 ? '0' + d : d; //日期不满10天显示前加0
      //也可以获取更精准时间
      let h = date.getHours(); //小时
      h = h < 10 ? '0' + h : h; //月份不满10天显示前加0
      let mi = date.getMinutes(); //分
      mi = mi < 10 ? '0' + mi : mi; //月份不满10天显示前加0
      let s = date.getSeconds(); //秒
      s = s < 10 ? '0' + s : s; //月份不满10天显示前加0
      return y + '-' + m + '-' + d + ' ' + h + ':' + mi + ':' + s;
    },
  },
  mounted() {
    this.JobList();
    console.log(
      'teachclass_id',
      JSON.parse(localStorage.getItem('teachclass_id'))
    );
  },
  methods: {
    //点击消息通知添加作业
    toApproval() {
      this.$notify.closeAll();
      console.log('JobListData111', this.JobListData);
      let data = this.JobListData.find((item, index) => index == 9);
      data.name = '4-1班数学活动单2023-6-5';
      data.beginTimeNo = '2023-6-5 14:00:00';
      data.endTimeNo = '2023-6-5 17:00:00';
      data.progress = 0;
      data.status = '未完成';
      //删掉最后一个数据 加一个数据进去
      this.JobListData.splice(9, 1);
      this.JobListData.unshift(data);
      localStorage.removeItem('/Class1');
      localStorage.setItem(
        `/Class1`,
        JSON.stringify({
          currectIndex: 1,
          list: this.JobListData,
        })
      );
    },

    stepQuestions(data) {
      let canshu = {
        classId: this.$parent.classId,
        ids: [
          this.$Download.getQuestionSet(data.resourceRelationList, 'chapter'),
        ],
        planId: data.id,
        relationKind: 'chapter',
        setId: this.$Download.getQuestionSet(
          data.resourceRelationList,
          'questionSet'
        ),
      };
      queryQuestionListBySetDegreeTactics(canshu), then((res) => {});
    },
    // 下载
    ButtonMiddle(params, stutas, url) {
      // debugger;
      if (stutas == '1') {
        this.go_to(url);
      } else if (stutas == '2') {
        let canshu = this.$Download.getQuestionSet(
          params.resourceRelationList,
          'questionSet'
        );
        // debugger;
        this.$Download.downloads(canshu.codeType, canshu.relationId);
      }
    },
    //改变当前页
    changeIndex(e) {
      this.currectIndex = e;
      this.JobList();
    },
    // 作业列表zdx
    JobList() {
      this.successForIn = false; //每次进入先变成false
      let that = this;
      let data = {
        fromId: 1000318,
      };
      ///Class  /InClass /afterClass
      //获取所有sessionStorage数据
      let dataAll = localStorage.valueOf();
      for (let key in dataAll) {
        if (key == `${that.$route.path}${that.currectIndex}`) {
          that.successForIn = true;
        }
      }
      //上面在本地找到了当前页 终止函数
      if (that.successForIn) {
        that.JobListData = JSON.parse(
          localStorage.getItem(`${that.$route.path}${that.currectIndex}`)
        ).list;
        return;
      }
      getPageWorks(data).then((res) => {
        if (this.$route.path == '/Class' && this.currectIndex == 1) {
          //进入课前 跳出通知弹窗
          setTimeout(() => {
            this.$notify({
              title: '提示:收到1份课件',
              dangerouslyUseHTMLString: true,
              message:
                '<strong>4-1班:列方程解决问题(三)</strong><br/><button style = "background :#377FF6 ;color:#fff ; border:0px ; margin-top : 5px" >确认添加</button>',
              type: 'success',
              duration: 0,
              // showClose: false,
              onClick: () => {
                this.toApproval();
              },
              onClose: () => {
                console.log(`Notify已关闭,说明异常或已查看`);
              },
            });
          }, 5000);
        }
        // //返回的17条数据  将本地的列表在内的数据删除
        // let homeWorkList = [];
        // if (localStorage.getItem('ClickHomeWork')) {
        //   homeWorkList = JSON.parse(localStorage.getItem('ClickHomeWork'));
        //   homeWorkList = this.removeDuplication(res.data.result, homeWorkList);
        // } else {
        //   //进入平台还没点过讲解
        //   homeWorkList = res.data.result;
        // }

        //截取10条数据并打乱顺序
        this.JobListData = this.randSort2(res.data.result);
        //给他们加个进度条  80-100
        this.JobListData.map((item) => {
          item.progress = Math.floor(Math.random() * (100 - 80)) + 80;
        });

        //最后一页的截取
        if (that.$route.path == '/Class' && that.currectIndex == 34) {
          this.JobListData = this.JobListData.slice(0, 4);
        }
        if (that.$route.path == '/InClass' && that.currectIndex == 82) {
          this.JobListData = this.JobListData.slice(0, 1);
        }
        if (that.$route.path == '/afterClass' && that.currectIndex == 27) {
          this.JobListData = this.JobListData.slice(0, 7);
        }

        this;

        //倒数两页和第一页  时间名称数据给死 String(arr).replace(/,/g,'-')
        if (
          that.$route.path == '/Class' &&
          (that.currectIndex == 34 || that.currectIndex == 33)
        ) {
          if (that.currectIndex == 33) {
            this.JobListData.forEach((item, index, arr) => {
              if (index < 7) {
                arr[index].name =
                  '卢湾活动单' + String([2022, 9, 5]).replace(/,/g, '-');
                arr[index].beginTimeNo =
                  String([2022, 9, 5]).replace(/,/g, '-') +
                  ` ${Math.floor(Math.random() * (15 - 14) + 14)}:${
                    Math.random() > 0.5 ? '30' : '00'
                  }:00`;
                arr[index].endTimeNo =
                  String([2022, 9, 5]).replace(/,/g, '-') +
                  ` ${Math.floor(Math.random() * (17 - 15) + 15)}:${
                    Math.random() > 0.5 ? '30' : '00'
                  }:00`;
              } else {
                arr[index].name =
                  '卢湾活动单' + String([2022, 9, 4]).replace(/,/g, '-');
                arr[index].beginTimeNo =
                  String([2022, 9, 4]).replace(/,/g, '-') +
                  ` ${Math.floor(Math.random() * (11 - 9) + 9)}:${
                    Math.random() > 0.5 ? '30' : '00'
                  }:00`;
                arr[index].endTimeNo =
                  String([2022, 9, 4]).replace(/,/g, '-') +
                  ` ${Math.floor(Math.random() * (12 - 11) + 11)}:${
                    Math.random() > 0.5 ? '30' : '00'
                  }:00`;
              }
            });
          } else if (that.currectIndex == 34) {
            this.JobListData.forEach((item, index, arr) => {
              if (index == 0) {
                arr[index].name =
                  '卢湾活动单' + String([2022, 9, 4]).replace(/,/g, '-');
                arr[index].beginTimeNo =
                  String([2022, 9, 4]).replace(/,/g, '-') + ' 15:30:00';
                arr[index].endTimeNo =
                  String([2022, 9, 4]).replace(/,/g, '-') +
                  ` ${Math.random() > 0.5 ? '17' : '16'}:${
                    Math.random() > 0.5 ? '30' : '00'
                  }:00`;
              } else if (index == 1) {
                arr[index].name =
                  '卢湾活动单' + String([2022, 9, 3]).replace(/,/g, '-');
                arr[index].beginTimeNo =
                  String([2022, 9, 3]).replace(/,/g, '-') + ' 16:30:00';
                arr[index].endTimeNo =
                  String([2022, 9, 3]).replace(/,/g, '-') + ' 17:30:00';
              } else {
                arr[index].name =
                  '卢湾活动单' + String([2022, 9, 2]).replace(/,/g, '-');
                arr[index].beginTimeNo =
                  String([2022, 9, 2]).replace(/,/g, '-') +
                  ` ${Math.random() > 0.5 ? '15' : '14'}:${
                    Math.random() > 0.5 ? '30' : '00'
                  }:00`;
                arr[index].endTimeNo =
                  String([2022, 9, 2]).replace(/,/g, '-') +
                  ` ${Math.random() > 0.5 ? '16' : '17'}:${
                    Math.random() > 0.5 ? '30' : '00'
                  }:00`;
              }
            });
          }
        } else if (
          that.$route.path == '/InClass' &&
          (that.currectIndex == 82 || that.currectIndex == 81)
        ) {
          if (that.currectIndex == 81) {
            this.JobListData.forEach((item, index, arr) => {
              if (index < 5) {
                arr[index].name =
                  '卢湾活动单' + String([2022, 9, 6]).replace(/,/g, '-');
                arr[index].beginTimeNo =
                  String([2022, 9, 6]).replace(/,/g, '-') +
                  ` ${Math.random() > 0.5 ? '15' : '14'}:${
                    Math.random() > 0.5 ? '30' : '00'
                  }:00`;
                arr[index].endTimeNo =
                  String([2022, 9, 6]).replace(/,/g, '-') +
                  ` ${Math.random() > 0.5 ? '16' : '17'}:${
                    Math.random() > 0.5 ? '30' : '00'
                  }:00`;
              } else {
                arr[index].name =
                  '卢湾活动单' + String([2022, 9, 5]).replace(/,/g, '-');
                arr[index].beginTimeNo =
                  String([2022, 9, 5]).replace(/,/g, '-') +
                  ` ${Math.random() > 0.5 ? '9' : '10'}:${
                    Math.random() > 0.5 ? '30' : '00'
                  }:00`;
                arr[index].endTimeNo =
                  String([2022, 9, 5]).replace(/,/g, '-') +
                  ` 11:${Math.random() > 0.5 ? '30' : '00'}:00`;
              }
            });
          } else if (that.currectIndex == 82) {
            this.JobListData.forEach((item, index, arr) => {
              arr[index].name =
                '卢湾活动单' + String([2022, 9, 5]).replace(/,/g, '-');
              arr[index].beginTimeNo =
                String([2022, 9, 5]).replace(/,/g, '-') + ' 14:30:00';
              arr[index].endTimeNo =
                String([2022, 9, 5]).replace(/,/g, '-') + ' 16:00:00';
            });
          }
        } else if (
          that.$route.path == '/afterClass' &&
          (that.currectIndex == 27 || that.currectIndex == 26)
        ) {
          if (that.currectIndex == 26) {
            this.JobListData.forEach((item, index, arr) => {
              if (index < 4) {
                arr[index].name =
                  '卢湾活动单' + String([2022, 9, 7]).replace(/,/g, '-');
                arr[index].beginTimeNo =
                  String([2022, 9, 7]).replace(/,/g, '-') +
                  ` ${Math.random() > 0.5 ? '16' : '14'}:${
                    Math.random() > 0.5 ? '30' : '00'
                  }:00`;
                arr[index].endTimeNo =
                  String([2022, 9, 7]).replace(/,/g, '-') +
                  ` ${Math.random() > 0.5 ? '17' : '16'}:${
                    Math.random() > 0.5 ? '30' : '00'
                  }:00`;
              } else {
                arr[index].name =
                  '卢湾活动单' + String([2022, 9, 6]).replace(/,/g, '-');
                arr[index].beginTimeNo =
                  String([2022, 9, 6]).replace(/,/g, '-') +
                  ` ${Math.random() > 0.5 ? '15' : '14'}:${
                    Math.random() > 0.5 ? '30' : '00'
                  }:00`;
                arr[index].endTimeNo =
                  String([2022, 9, 6]).replace(/,/g, '-') +
                  ` ${Math.random() > 0.5 ? '17' : '16'}:${
                    Math.random() > 0.5 ? '30' : '00'
                  }:00`;
              }
            });
          } else if (that.currectIndex == 27) {
            this.JobListData.forEach((item, index, arr) => {
              if (index < 3) {
                arr[index].name =
                  '卢湾活动单' + String([2022, 9, 5]).replace(/,/g, '-');
                arr[index].beginTimeNo =
                  String([2022, 9, 5]).replace(/,/g, '-') +
                  ` ${Math.random() > 0.5 ? '16' : '15'}:${
                    Math.random() > 0.5 ? '30' : '00'
                  }:00`;
                arr[index].endTimeNo =
                  String([2022, 9, 5]).replace(/,/g, '-') +
                  ` 17:${Math.random() > 0.5 ? '30' : '00'}:00`;
              } else {
                arr[index].name =
                  '卢湾活动单' + String([2022, 9, 3]).replace(/,/g, '-');
                arr[index].beginTimeNo =
                  String([2022, 9, 3]).replace(/,/g, '-') +
                  ` ${Math.random() > 0.5 ? '9' : '10'}:${
                    Math.random() > 0.5 ? '30' : '00'
                  }:00`;
                arr[index].endTimeNo =
                  String([2022, 9, 3]).replace(/,/g, '-') +
                  ` ${Math.random() > 0.5 ? '11' : '12'}:${
                    Math.random() > 0.5 ? '30' : '00'
                  }:00`;
              }
            });
          }
        } else if (that.$route.path == '/Class' && that.currectIndex == 1) {
          //课前第一页
          this.JobListData.forEach((item, index, arr) => {
            if (index == 0) {
              arr[index].name = '4-3班数学活动单2023-5-29';
              arr[index].beginTimeNo = '2023-5-29 14:00:00';
              arr[index].endTimeNo = '2023-5-29 16:00:00';
            }
            if (index == 1) {
              arr[index].name = '3-2班数学活动单2023-5-29';
              arr[index].beginTimeNo = '2023-5-29 14:00:00';
              arr[index].endTimeNo = '2023-5-29 15:00:00';
            }
            if (index == 2) {
              arr[index].name = '3-6班自然活动单2023-5-29';
              arr[index].beginTimeNo = '2023-5-29 9:00:00';
              arr[index].endTimeNo = '2023-5-29 11:30:00';
            }
            if (index == 3) {
              arr[index].name = '5-1班自然活动单2023-5-29';
              arr[index].beginTimeNo = '2023-5-29 9:00:00';
              arr[index].endTimeNo = '2023-5-29 11:30:00';
            }
            if (index == 4) {
              arr[index].name = '2-3班语文活动单2023-5-29';
              arr[index].beginTimeNo = '2023-5-29 9:00:00';
              arr[index].endTimeNo = '2023-5-29 11:00:00';
            }
            if (index == 5) {
              arr[index].name = '4-1班语文活动单2023-5-26';
              arr[index].beginTimeNo = '2023-5-26 14:00:00';
              arr[index].endTimeNo = '2023-5-26 17:00:00';
            }
            if (index == 6) {
              arr[index].name = '5-1班数学活动单2023-5-24';
              arr[index].beginTimeNo = '2023-5-24 15:00:00';
              arr[index].endTimeNo = '2023-5-24 16:30:00';
            }
            if (index == 7) {
              arr[index].name = '4-3班数学活动单2023-5-24';
              arr[index].beginTimeNo = '2023-5-24 9:00:00';
              arr[index].endTimeNo = '2023-5-24 11:30:00';
            }
            if (index == 8) {
              arr[index].name = '3-3班自然活动单2023-5-23';
              arr[index].beginTimeNo = '2023-5-23 14:00:00';
              arr[index].endTimeNo = '2023-5-23 15:00:00';
            }
            if (index == 9) {
              arr[index].name = '4-6班语文活动单2023-5-23';
              arr[index].beginTimeNo = '2023-5-23 9:00:00';
              arr[index].endTimeNo = '2023-5-23 11:00:00';
            }
          });
        } else if (that.$route.path == '/InClass' && that.currectIndex == 1) {
          //课中第一页
          this.JobListData.forEach((item, index, arr) => {
            if (index == 0) {
              arr[index].name = '4-3班数学活动单2023-5-30';
              arr[index].beginTimeNo = '2023-5-30 14:00:00';
              arr[index].endTimeNo = '2023-5-30 16:30:00';
            }
            if (index == 1) {
              arr[index].name = '3-2班数学活动单2023-5-30';
              arr[index].beginTimeNo = '2023-5-30 14:00:00';
              arr[index].endTimeNo = '2023-5-30 15:00:00';
            }
            if (index == 2) {
              arr[index].name = '3-6班自然活动单2023-5-30';
              arr[index].beginTimeNo = '2023-5-30 10:00:00';
              arr[index].endTimeNo = '2023-5-30 11:30:00';
            }
            if (index == 3) {
              arr[index].name = '5-1班自然活动单2023-5-30';
              arr[index].beginTimeNo = '2023-5-30 9:00:00';
              arr[index].endTimeNo = '2023-5-30 11:00:00';
            }
            if (index == 4) {
              arr[index].name = '2-3班语文活动单2023-5-29';
              arr[index].beginTimeNo = '2023-5-29 14:00:00';
              arr[index].endTimeNo = '2023-5-29 16:00:00';
            }
            if (index == 5) {
              arr[index].name = '4-1班语文活动单2023-5-26';
              arr[index].beginTimeNo = '2023-5-26 14:00:00';
              arr[index].endTimeNo = '2023-5-26 17:00:00';
            }
            if (index == 6) {
              arr[index].name = '5-1班数学活动单2023-5-25';
              arr[index].beginTimeNo = '2023-5-25 15:00:00';
              arr[index].endTimeNo = '2023-5-25 16:30:00';
            }
            if (index == 7) {
              arr[index].name = '4-3班数学活动单2023-5-25';
              arr[index].beginTimeNo = '2023-5-25 9:00:00';
              arr[index].endTimeNo = '2023-5-25 11:30:00';
            }
            if (index == 8) {
              arr[index].name = '3-3班自然活动单2023-5-24';
              arr[index].beginTimeNo = '2023-5-24 14:00:00';
              arr[index].endTimeNo = '2023-5-24 15:30:00';
            }
            if (index == 9) {
              arr[index].name = '4-6班语文活动单2023-5-23';
              arr[index].beginTimeNo = '2023-5-23 9:00:00';
              arr[index].endTimeNo = '2023-5-23 11:30:00';
            }
          });
        } else if (
          that.$route.path == '/afterClass' &&
          that.currectIndex == 1
        ) {
          //课后第一页
          this.JobListData.forEach((item, index, arr) => {
            if (index == 0) {
              arr[index].name = '4-3班数学活动单2023-5-31';
              arr[index].beginTimeNo = '2023-5-31 9:00:00';
              arr[index].endTimeNo = '2023-5-31 16:30:00';
            }
            if (index == 1) {
              arr[index].name = '3-2班数学活动单2023-5-31';
              arr[index].beginTimeNo = '2023-5-31 9:00:00';
              arr[index].endTimeNo = '2023-5-31 15:00:00';
            }
            if (index == 2) {
              arr[index].name = '3-6班自然活动单2023-5-31';
              arr[index].beginTimeNo = '2023-5-31 9:00:00';
              arr[index].endTimeNo = '2023-5-31 17:00:00';
            }
            if (index == 3) {
              arr[index].name = '5-1班自然活动单2023-5-31';
              arr[index].beginTimeNo = '2023-5-31 9:00:00';
              arr[index].endTimeNo = '2023-5-31 16:00:00';
            }
            if (index == 4) {
              arr[index].name = '2-3班语文活动单2023-5-30';
              arr[index].beginTimeNo = '2023-5-29 10:00:00';
              arr[index].endTimeNo = '2023-5-29 16:00:00';
            }
            if (index == 5) {
              arr[index].name = '4-1班语文活动单2023-5-26';
              arr[index].beginTimeNo = '2023-5-26 9:00:00';
              arr[index].endTimeNo = '2023-5-26 16:00:00';
            }
            if (index == 6) {
              arr[index].name = '5-1班数学活动单2023-5-26';
              arr[index].beginTimeNo = '2023-5-26 9:00:00';
              arr[index].endTimeNo = '2023-5-26 11:30:00';
            }
            if (index == 7) {
              arr[index].name = '4-3班数学活动单2023-5-26';
              arr[index].beginTimeNo = '2023-5-26 9:00:00';
              arr[index].endTimeNo = '2023-5-26 16:30:00';
            }
            if (index == 8) {
              arr[index].name = '3-3班自然活动单2023-5-25';
              arr[index].beginTimeNo = '2023-5-25 9:00:00';
              arr[index].endTimeNo = '2023-5-25 16:00:00';
            }
            if (index == 9) {
              arr[index].name = '4-6班语文活动单2023-5-24';
              arr[index].beginTimeNo = '2023-5-24 9:00:00';
              arr[index].endTimeNo = '2023-5-24 16:00:00';
            }
          });
        } else {
          this.randTimeAndMame();
        }

        let setData = {
          currectIndex: that.currectIndex,
          list: this.JobListData,
        };
        localStorage.setItem(
          `${that.$route.path}${that.currectIndex}`,
          JSON.stringify(setData)
        );
        // console.log(res);
      });
    },
    //两个对象数组去重 返回新数组
    removeDuplication(arr1, arr2) {
      return arr1.filter((v) => arr2.every((val) => val != v.id));
    },
    //随机排序数组 并按照数量返回
    randSort2(arr) {
      let that = this;
      let otherNum = 0;
      //区分点进入的是那个标签
      if (that.$route.path == '/Class') {
        otherNum = 0;
      } else if (that.$route.path == '/InClass') {
        otherNum = 1000;
      } else if (that.$route.path == '/afterClass') {
        otherNum = 2000;
      }
      let mixedArray = [];
      while (arr.length > 0) {
        var randomIndex = parseInt(Math.random() * arr.length);
        mixedArray.push(arr[randomIndex]);
        arr.splice(randomIndex, 1);
      }
      mixedArray = mixedArray.slice(0, 10);
      //给每个作业一个序号
      for (let i = 0; i < mixedArray.length; i++) {
        mixedArray[i].dataIndex = (that.currectIndex - 1) * 10 + i + otherNum;
      }
      return mixedArray;
    },
    //随机时间
    randTimeAndMame() {
      let that = this;
      //给开始时间和名字 每一页的时间范围：arrTime[(arrTime.length -that.currectIndex*(Math.random()>0.5?2:3) )-arrTime.length -that.currectIndex]
      let arrTime = [
        [2022, 9, 1],
        [2022, 9, 2],
        [2022, 9, 5],
        [2022, 9, 6],
        [2022, 9, 7],
        [2022, 9, 8],
        [2022, 9, 9],
        [2022, 9, 13],
        [2022, 9, 14],
        [2022, 9, 15],
        [2022, 9, 16],
        [2022, 9, 19],
        [2022, 9, 20],
        [2022, 9, 21],
        [2022, 9, 22],
        [2022, 9, 23],
        [2022, 9, 26],
        [2022, 9, 27],
        [2022, 9, 28],
        [2022, 9, 29],
        [2022, 9, 30],
        [2022, 10, 8],
        [2022, 10, 9],
        [2022, 10, 10],
        [2022, 10, 11],
        [2022, 10, 12],
        [2022, 10, 13],
        [2022, 10, 14],
        [2022, 10, 17],
        [2022, 10, 18],
        [2022, 10, 19],
        [2022, 10, 20],
        [2022, 10, 21],
        [2022, 10, 24],
        [2022, 10, 25],
        [2022, 10, 26],
        [2022, 10, 27],
        [2022, 10, 28],
        [2023, 1, 18],
        [2023, 1, 19],
        [2023, 1, 20],
        [2023, 1, 28],
        [2023, 1, 29],
        [2023, 1, 30],
        [2023, 1, 31],
        [2023, 2, 1],
        [2023, 2, 2],
        [2023, 2, 3],
        [2023, 2, 6],
        [2023, 2, 7],
        [2023, 2, 8],
        [2023, 2, 9],
        [2023, 2, 10],
        [2023, 2, 13],
        [2023, 2, 14],
        [2023, 2, 15],
        [2023, 2, 16],
        [2023, 2, 17],
        [2023, 2, 20],
        [2023, 2, 21],
        [2023, 2, 22],
        [2023, 2, 23],
        [2023, 2, 24],
        [2023, 2, 27],
        [2023, 2, 28],
        [2023, 3, 1],
        [2023, 3, 2],
        [2023, 3, 3],
        [2023, 3, 6],
        [2023, 3, 7],
        [2023, 3, 8],
        [2023, 3, 9],
        [2023, 3, 10],
        [2023, 3, 13],
        [2023, 3, 14],
        [2023, 3, 15],
        [2023, 3, 16],
        [2023, 3, 17],
        [2023, 3, 20],
        [2023, 3, 21],
        [2023, 3, 22],
        [2023, 3, 23],
        [2023, 3, 24],
        [2023, 3, 27],
        [2023, 3, 28],
        [2023, 3, 29],
        [2023, 3, 30],
        [2023, 3, 31],
        [2023, 4, 3],
        [2023, 4, 4],
        [2023, 4, 6],
        [2023, 4, 7],
        [2023, 4, 10],
        [2023, 4, 11],
        [2023, 4, 12],
        [2023, 4, 13],
        [2023, 4, 14],
        [2023, 4, 17],
        [2023, 4, 18],
        [2023, 4, 19],
        [2023, 4, 20],
        [2023, 4, 21],
        [2023, 4, 23],
        [2023, 4, 24],
        [2023, 4, 25],
        [2023, 4, 26],
        [2023, 4, 27],
        [2023, 4, 28],
        [2023, 5, 4],
        [2023, 5, 5],
        [2023, 5, 6],
        [2023, 5, 8],
        [2023, 5, 9],
        [2023, 5, 10],
        [2023, 5, 11],
        [2023, 5, 12],
        [2023, 5, 15],
        [2023, 5, 16],
        [2023, 5, 17],
        [2023, 5, 18],
        [2023, 5, 19],
        [2023, 5, 22],
        [2023, 5, 23],
        [2023, 5, 24],
        [2023, 5, 25],
        [2023, 5, 26],
        [2023, 5, 29],
        [2023, 5, 30],
        [2023, 5, 31],
      ];
      //获取课前时间每一页有几个时间  （2-3个）
      let total = Number(String(that.total).slice(0, 2)) + 1;
      let randomRang = [
        Math.floor(arrTime.length / total),
        Math.floor(arrTime.length / total) + 1,
      ];
      //   随机取1||2
      let randomNum =
        Math.floor(Math.random() * (randomRang[1] - randomRang[0] + 1)) +
        randomRang[0];
      //获取这一页的时间随机范围      [128-cur*(len_arrTime/total)-rand,128-cur*(len_arrTime/total)]           最后＋1因为slice取不到
      let arrTimeRangSort = arrTime.slice(
        arrTime.length -
          1 -
          Math.floor(that.currectIndex * (arrTime.length / total)) -
          randomNum,
        arrTime.length -
          1 -
          Math.floor(that.currectIndex * (arrTime.length / total))
      );
      //将时间从大到小排序
      let arrTimeRang = [];
      arrTimeRangSort.forEach((element) => {
        arrTimeRang.unshift(element);
      });
      //创建作业截取的索引
      let homeworkSliceList = [];
      for (let i = 0; i < randomNum - 1; i++) {
        //  返回两个  0-10 的 随机数
        homeworkSliceList.push(Math.floor(Math.random() * 10));
      }
      //给索引大到小排序
      if (homeworkSliceList.length > 1) {
        homeworkSliceList = homeworkSliceList.sort(function(a, b) {
          return a - b;
        });
      }
      let j = 0;
      let i = 0;

      for (; i < arrTimeRang.length; i++) {
        for (; j < homeworkSliceList[i]; j++) {
          let subjects = ['语文', '数学', '英语', '自然'];

          let subjectName =
            subjects[Math.floor(Math.random() * subjects.length)];
          this.JobListData[j].name =
            `${Math.floor(Math.random() * (6 - 1)) + 1}-` +
            `${Math.floor(Math.random() * (7 - 1)) + 1}` +
            '班' +
            subjectName +
            '活动单' +
            String(arrTimeRang[i]).replace(/,/g, '-');

          this.JobListData[j].beginTimeNo =
            String(arrTimeRang[i]).replace(/,/g, '-') +
            ` ${Math.random() > 0.5 ? '9' : '10'}:${
              Math.random() > 0.5 ? '30' : '00'
            }:00`;
          this.JobListData[j].endTimeNo =
            String(arrTimeRang[i]).replace(/,/g, '-') +
            ` ${Math.random() > 0.5 ? '17' : '15'}:${
              Math.random() > 0.5 ? '30' : '00'
            }:00`;
        }
      }
      for (; j < 10; j++) {
        let subjects = ['语文', '数学', '英语', '自然'];

        let subjectName = subjects[Math.floor(Math.random() * subjects.length)];
        this.JobListData[j].name =
          `${Math.floor(Math.random() * (6 - 1)) + 1}-` +
          `${Math.floor(Math.random() * (7 - 1)) + 1}` +
          '班' +
          subjectName +
          '活动单' +
          String(arrTimeRang[i - 1]).replace(/,/g, '-');
        this.JobListData[j].beginTimeNo =
          String(arrTimeRang[i - 1]).replace(/,/g, '-') +
          ` ${Math.random() > 0.5 ? '11' : '10'}:${
            Math.random() > 0.5 ? '30' : '00'
          }:00`;
        this.JobListData[j].endTimeNo =
          String(arrTimeRang[i - 1]).replace(/,/g, '-') +
          ` ${Math.random() > 0.5 ? '12' : '16'}:${
            Math.random() > 0.5 ? '30' : '00'
          }:00`;
      }
    },
    // 修改上课时间
    updatebaseInfo(val1, val2, val3) {
      let data = {
        id: val1.id,
        [val3]: val2 - 1,
      };
      updateBaseInfo(data).then((res) => {
        this.JobList();
      });
    },
    go_to(a, b, c, url, d, show) {
      let plan_id = '';
      let start_time = '';
      let end_time = '';
      let data = null;
      //  go_to(row.id, row.beginTime, row.endTime, 'Explain', row, true)
      console.log('clickNum:', d.dataIndex);
      let that = this;
      let arr = [
        {
          id: 8949,
          name: '卢湾活动单0504自然',
          code: null,
          planKind: '作业',
          stageKind: '课中',
          dateKind: null,
          beginTime: 1683243000000,
          endTime: 1683252000000,
          serial: null,
          num: 1,
          roomId: 576,
          attributeKind: null,
          attributeId: null,
          planTime: null,
          planBy: null,
          roleKind: null,
          createTime: 1683245531000,
          createBy: 4363,
          updateBy: null,
          updateTime: null,
          parentId: null,
          status: '1',
          auditId: null,
          auditStatus: null,
          finishedUser: null,
          totalUser: null,
          totalResource: null,
          progress: null,
          avgTime: null,
          fromId: 1000318,
          fromKind: null,
          flag: null,
          joinStatus: null,
          classRelationList: null,
          resourceRelationList: [
            {
              id: 16691,
              planId: 8949,
              relationId: 2364368931242932,
              relationName: '卢湾活动单0504',
              relationSerial: null,
              relationKind: 'questionSet',
              codeType: '1',
              status: 1,
              width: 210,
              height: 297,
            },
          ],
          classId: 574,
          subjectId: 100,
          pageIds: null,
          joinedUserList: [
            {
              id: 4293,
              name: '1',
              type: null,
              status: null,
              corpId: null,
              offset: null,
              penMac: null,
              mobile: null,
              openId: null,
              serial: null,
            },
            {
              id: 4294,
              name: '2',
              type: null,
              status: null,
              corpId: null,
              offset: null,
              penMac: null,
              mobile: null,
              openId: null,
              serial: null,
            },
            {
              id: 4295,
              name: '3',
              type: null,
              status: null,
              corpId: null,
              offset: null,
              penMac: null,
              mobile: null,
              openId: null,
              serial: null,
            },
            {
              id: 4296,
              name: '4',
              type: null,
              status: null,
              corpId: null,
              offset: null,
              penMac: null,
              mobile: null,
              openId: null,
              serial: null,
            },
            {
              id: 4297,
              name: '5',
              type: null,
              status: null,
              corpId: null,
              offset: null,
              penMac: null,
              mobile: null,
              openId: null,
              serial: null,
            },
            {
              id: 4298,
              name: '6',
              type: null,
              status: null,
              corpId: null,
              offset: null,
              penMac: null,
              mobile: null,
              openId: null,
              serial: null,
            },
            {
              id: 4299,
              name: '7',
              type: null,
              status: null,
              corpId: null,
              offset: null,
              penMac: null,
              mobile: null,
              openId: null,
              serial: null,
            },
            {
              id: 4300,
              name: '8',
              type: null,
              status: null,
              corpId: null,
              offset: null,
              penMac: null,
              mobile: null,
              openId: null,
              serial: null,
            },
            {
              id: 4301,
              name: '9',
              type: null,
              status: null,
              corpId: null,
              offset: null,
              penMac: null,
              mobile: null,
              openId: null,
              serial: null,
            },
            {
              id: 4304,
              name: '12',
              type: null,
              status: null,
              corpId: null,
              offset: null,
              penMac: null,
              mobile: null,
              openId: null,
              serial: null,
            },
            {
              id: 4305,
              name: '13',
              type: null,
              status: null,
              corpId: null,
              offset: null,
              penMac: null,
              mobile: null,
              openId: null,
              serial: null,
            },
            {
              id: 4306,
              name: '14',
              type: null,
              status: null,
              corpId: null,
              offset: null,
              penMac: null,
              mobile: null,
              openId: null,
              serial: null,
            },
            {
              id: 4307,
              name: '15',
              type: null,
              status: null,
              corpId: null,
              offset: null,
              penMac: null,
              mobile: null,
              openId: null,
              serial: null,
            },
            {
              id: 4308,
              name: '16',
              type: null,
              status: null,
              corpId: null,
              offset: null,
              penMac: null,
              mobile: null,
              openId: null,
              serial: null,
            },
            {
              id: 4309,
              name: '17',
              type: null,
              status: null,
              corpId: null,
              offset: null,
              penMac: null,
              mobile: null,
              openId: null,
              serial: null,
            },
            {
              id: 4310,
              name: '18',
              type: null,
              status: null,
              corpId: null,
              offset: null,
              penMac: null,
              mobile: null,
              openId: null,
              serial: null,
            },
            {
              id: 4311,
              name: '19',
              type: null,
              status: null,
              corpId: null,
              offset: null,
              penMac: null,
              mobile: null,
              openId: null,
              serial: null,
            },
            {
              id: 4313,
              name: '21',
              type: null,
              status: null,
              corpId: null,
              offset: null,
              penMac: null,
              mobile: null,
              openId: null,
              serial: null,
            },
            {
              id: 4314,
              name: '22',
              type: null,
              status: null,
              corpId: null,
              offset: null,
              penMac: null,
              mobile: null,
              openId: null,
              serial: null,
            },
            {
              id: 4315,
              name: '23',
              type: null,
              status: null,
              corpId: null,
              offset: null,
              penMac: null,
              mobile: null,
              openId: null,
              serial: null,
            },
            {
              id: 4316,
              name: '24',
              type: null,
              status: null,
              corpId: null,
              offset: null,
              penMac: null,
              mobile: null,
              openId: null,
              serial: null,
            },
            {
              id: 4317,
              name: '25',
              type: null,
              status: null,
              corpId: null,
              offset: null,
              penMac: null,
              mobile: null,
              openId: null,
              serial: null,
            },
            {
              id: 4319,
              name: '27',
              type: null,
              status: null,
              corpId: null,
              offset: null,
              penMac: null,
              mobile: null,
              openId: null,
              serial: null,
            },
            {
              id: 4320,
              name: '28',
              type: null,
              status: null,
              corpId: null,
              offset: null,
              penMac: null,
              mobile: null,
              openId: null,
              serial: null,
            },
            {
              id: 4321,
              name: '29',
              type: null,
              status: null,
              corpId: null,
              offset: null,
              penMac: null,
              mobile: null,
              openId: null,
              serial: null,
            },
            {
              id: 4323,
              name: '31',
              type: null,
              status: null,
              corpId: null,
              offset: null,
              penMac: null,
              mobile: null,
              openId: null,
              serial: null,
            },
            {
              id: 4324,
              name: '32',
              type: null,
              status: null,
              corpId: null,
              offset: null,
              penMac: null,
              mobile: null,
              openId: null,
              serial: null,
            },
            {
              id: 4325,
              name: '33',
              type: null,
              status: null,
              corpId: null,
              offset: null,
              penMac: null,
              mobile: null,
              openId: null,
              serial: null,
            },
            {
              id: 4326,
              name: '34',
              type: null,
              status: null,
              corpId: null,
              offset: null,
              penMac: null,
              mobile: null,
              openId: null,
              serial: null,
            },
            {
              id: 4327,
              name: '35',
              type: null,
              status: null,
              corpId: null,
              offset: null,
              penMac: null,
              mobile: null,
              openId: null,
              serial: null,
            },
          ],
          unJoinUserList: [
            {
              id: 4302,
              name: '10',
              type: null,
              status: null,
              corpId: null,
              offset: null,
              penMac: null,
              mobile: null,
              openId: null,
              serial: null,
            },
            {
              id: 4303,
              name: '11',
              type: null,
              status: null,
              corpId: null,
              offset: null,
              penMac: null,
              mobile: null,
              openId: null,
              serial: null,
            },
            {
              id: 4312,
              name: '20',
              type: null,
              status: null,
              corpId: null,
              offset: null,
              penMac: null,
              mobile: null,
              openId: null,
              serial: null,
            },
            {
              id: 4318,
              name: '26',
              type: null,
              status: null,
              corpId: null,
              offset: null,
              penMac: null,
              mobile: null,
              openId: null,
              serial: null,
            },
            {
              id: 4322,
              name: '30',
              type: null,
              status: null,
              corpId: null,
              offset: null,
              penMac: null,
              mobile: null,
              openId: null,
              serial: null,
            },
          ],
          revisedUserList: null,
          unReviseUserList: null,
          unCorrectUserList: null,
          correctedUserList: null,
          unEvaluateUserList: null,
          evaluatedUserList: null,
          planJoinResultVo: null,
        },
        {
          id: 8960,
          name: '卢湾活动单0509新版',
          code: null,
          planKind: '作业',
          stageKind: '课中',
          dateKind: null,
          beginTime: 1683590400000,
          endTime: 1683604800000,
          serial: null,
          num: 1,
          roomId: 582,
          attributeKind: null,
          attributeId: null,
          planTime: null,
          planBy: null,
          roleKind: null,
          createTime: 1683590963000,
          createBy: 4363,
          updateBy: null,
          updateTime: null,
          parentId: null,
          status: '1',
          auditId: null,
          auditStatus: null,
          finishedUser: null,
          totalUser: null,
          totalResource: null,
          progress: null,
          avgTime: null,
          fromId: 1000318,
          fromKind: null,
          flag: null,
          joinStatus: null,
          classRelationList: null,
          resourceRelationList: [
            {
              id: 16713,
              planId: 8960,
              relationId: 2364368931242937,
              relationName: '卢湾活动单0509新版',
              relationSerial: null,
              relationKind: 'questionSet',
              codeType: '1',
              status: 1,
              width: 210,
              height: 297,
            },
          ],
          classId: 574,
          subjectId: 100,
          pageIds: null,
          joinedUserList: [
            {
              id: 4293,
              name: '1',
              type: null,
              status: null,
              corpId: null,
              offset: null,
              penMac: null,
              mobile: null,
              openId: null,
              serial: null,
            },
            {
              id: 4294,
              name: '2',
              type: null,
              status: null,
              corpId: null,
              offset: null,
              penMac: null,
              mobile: null,
              openId: null,
              serial: null,
            },
            {
              id: 4295,
              name: '3',
              type: null,
              status: null,
              corpId: null,
              offset: null,
              penMac: null,
              mobile: null,
              openId: null,
              serial: null,
            },
            {
              id: 4296,
              name: '4',
              type: null,
              status: null,
              corpId: null,
              offset: null,
              penMac: null,
              mobile: null,
              openId: null,
              serial: null,
            },
            {
              id: 4298,
              name: '6',
              type: null,
              status: null,
              corpId: null,
              offset: null,
              penMac: null,
              mobile: null,
              openId: null,
              serial: null,
            },
            {
              id: 4299,
              name: '7',
              type: null,
              status: null,
              corpId: null,
              offset: null,
              penMac: null,
              mobile: null,
              openId: null,
              serial: null,
            },
            {
              id: 4300,
              name: '8',
              type: null,
              status: null,
              corpId: null,
              offset: null,
              penMac: null,
              mobile: null,
              openId: null,
              serial: null,
            },
            {
              id: 4302,
              name: '10',
              type: null,
              status: null,
              corpId: null,
              offset: null,
              penMac: null,
              mobile: null,
              openId: null,
              serial: null,
            },
            {
              id: 4303,
              name: '11',
              type: null,
              status: null,
              corpId: null,
              offset: null,
              penMac: null,
              mobile: null,
              openId: null,
              serial: null,
            },
            {
              id: 4304,
              name: '12',
              type: null,
              status: null,
              corpId: null,
              offset: null,
              penMac: null,
              mobile: null,
              openId: null,
              serial: null,
            },
            {
              id: 4305,
              name: '13',
              type: null,
              status: null,
              corpId: null,
              offset: null,
              penMac: null,
              mobile: null,
              openId: null,
              serial: null,
            },
            {
              id: 4306,
              name: '14',
              type: null,
              status: null,
              corpId: null,
              offset: null,
              penMac: null,
              mobile: null,
              openId: null,
              serial: null,
            },
            {
              id: 4307,
              name: '15',
              type: null,
              status: null,
              corpId: null,
              offset: null,
              penMac: null,
              mobile: null,
              openId: null,
              serial: null,
            },
            {
              id: 4309,
              name: '17',
              type: null,
              status: null,
              corpId: null,
              offset: null,
              penMac: null,
              mobile: null,
              openId: null,
              serial: null,
            },
            {
              id: 4310,
              name: '18',
              type: null,
              status: null,
              corpId: null,
              offset: null,
              penMac: null,
              mobile: null,
              openId: null,
              serial: null,
            },
            {
              id: 4311,
              name: '19',
              type: null,
              status: null,
              corpId: null,
              offset: null,
              penMac: null,
              mobile: null,
              openId: null,
              serial: null,
            },
            {
              id: 4312,
              name: '20',
              type: null,
              status: null,
              corpId: null,
              offset: null,
              penMac: null,
              mobile: null,
              openId: null,
              serial: null,
            },
            {
              id: 4313,
              name: '21',
              type: null,
              status: null,
              corpId: null,
              offset: null,
              penMac: null,
              mobile: null,
              openId: null,
              serial: null,
            },
            {
              id: 4314,
              name: '22',
              type: null,
              status: null,
              corpId: null,
              offset: null,
              penMac: null,
              mobile: null,
              openId: null,
              serial: null,
            },
            {
              id: 4315,
              name: '23',
              type: null,
              status: null,
              corpId: null,
              offset: null,
              penMac: null,
              mobile: null,
              openId: null,
              serial: null,
            },
            {
              id: 4316,
              name: '24',
              type: null,
              status: null,
              corpId: null,
              offset: null,
              penMac: null,
              mobile: null,
              openId: null,
              serial: null,
            },
            {
              id: 4317,
              name: '25',
              type: null,
              status: null,
              corpId: null,
              offset: null,
              penMac: null,
              mobile: null,
              openId: null,
              serial: null,
            },
            {
              id: 4318,
              name: '26',
              type: null,
              status: null,
              corpId: null,
              offset: null,
              penMac: null,
              mobile: null,
              openId: null,
              serial: null,
            },
            {
              id: 4319,
              name: '27',
              type: null,
              status: null,
              corpId: null,
              offset: null,
              penMac: null,
              mobile: null,
              openId: null,
              serial: null,
            },
            {
              id: 4320,
              name: '28',
              type: null,
              status: null,
              corpId: null,
              offset: null,
              penMac: null,
              mobile: null,
              openId: null,
              serial: null,
            },
            {
              id: 4321,
              name: '29',
              type: null,
              status: null,
              corpId: null,
              offset: null,
              penMac: null,
              mobile: null,
              openId: null,
              serial: null,
            },
            {
              id: 4322,
              name: '30',
              type: null,
              status: null,
              corpId: null,
              offset: null,
              penMac: null,
              mobile: null,
              openId: null,
              serial: null,
            },
            {
              id: 4323,
              name: '31',
              type: null,
              status: null,
              corpId: null,
              offset: null,
              penMac: null,
              mobile: null,
              openId: null,
              serial: null,
            },
            {
              id: 4324,
              name: '32',
              type: null,
              status: null,
              corpId: null,
              offset: null,
              penMac: null,
              mobile: null,
              openId: null,
              serial: null,
            },
            {
              id: 4325,
              name: '33',
              type: null,
              status: null,
              corpId: null,
              offset: null,
              penMac: null,
              mobile: null,
              openId: null,
              serial: null,
            },
            {
              id: 4326,
              name: '34',
              type: null,
              status: null,
              corpId: null,
              offset: null,
              penMac: null,
              mobile: null,
              openId: null,
              serial: null,
            },
            {
              id: 4327,
              name: '35',
              type: null,
              status: null,
              corpId: null,
              offset: null,
              penMac: null,
              mobile: null,
              openId: null,
              serial: null,
            },
          ],
          unJoinUserList: [
            {
              id: 4297,
              name: '5',
              type: null,
              status: null,
              corpId: null,
              offset: null,
              penMac: null,
              mobile: null,
              openId: null,
              serial: null,
            },
            {
              id: 4301,
              name: '9',
              type: null,
              status: null,
              corpId: null,
              offset: null,
              penMac: null,
              mobile: null,
              openId: null,
              serial: null,
            },
            {
              id: 4308,
              name: '16',
              type: null,
              status: null,
              corpId: null,
              offset: null,
              penMac: null,
              mobile: null,
              openId: null,
              serial: null,
            },
          ],
          revisedUserList: null,
          unReviseUserList: null,
          unCorrectUserList: null,
          correctedUserList: null,
          unEvaluateUserList: null,
          evaluatedUserList: null,
          planJoinResultVo: null,
        },
        {
          id: 8901,
          name: '长方体、正方体的表面积 课练卷-卢湾公开课',
          code: null,
          planKind: '作业',
          stageKind: '课中',
          dateKind: null,
          beginTime: 1681705800000,
          endTime: 1681722000000,
          serial: null,
          num: 1,
          roomId: 576,
          attributeKind: null,
          attributeId: null,
          planTime: null,
          planBy: null,
          roleKind: null,
          createTime: 1681705142000,
          createBy: 1,
          updateBy: null,
          updateTime: null,
          parentId: null,
          status: '1',
          auditId: null,
          auditStatus: null,
          finishedUser: null,
          totalUser: null,
          totalResource: null,
          progress: null,
          avgTime: null,
          fromId: 1000318,
          fromKind: null,
          flag: null,
          joinStatus: null,
          classRelationList: null,
          resourceRelationList: [
            {
              id: 16595,
              planId: 8901,
              relationId: 2364368931242910,
              relationName: '长方体、正方体的表面积 课练卷-卢湾公开课',
              relationSerial: null,
              relationKind: 'questionSet',
              codeType: '1',
              status: 1,
              width: 210,
              height: 297,
            },
          ],
          classId: 574,
          subjectId: 22,
          pageIds: null,
          joinedUserList: [
            {
              id: 4294,
              name: '2',
              type: null,
              status: null,
              corpId: null,
              offset: null,
              penMac: null,
              mobile: null,
              openId: null,
              serial: null,
            },
            {
              id: 4295,
              name: '3',
              type: null,
              status: null,
              corpId: null,
              offset: null,
              penMac: null,
              mobile: null,
              openId: null,
              serial: null,
            },
            {
              id: 4296,
              name: '4',
              type: null,
              status: null,
              corpId: null,
              offset: null,
              penMac: null,
              mobile: null,
              openId: null,
              serial: null,
            },
            {
              id: 4297,
              name: '5',
              type: null,
              status: null,
              corpId: null,
              offset: null,
              penMac: null,
              mobile: null,
              openId: null,
              serial: null,
            },
            {
              id: 4298,
              name: '6',
              type: null,
              status: null,
              corpId: null,
              offset: null,
              penMac: null,
              mobile: null,
              openId: null,
              serial: null,
            },
            {
              id: 4299,
              name: '7',
              type: null,
              status: null,
              corpId: null,
              offset: null,
              penMac: null,
              mobile: null,
              openId: null,
              serial: null,
            },
            {
              id: 4300,
              name: '8',
              type: null,
              status: null,
              corpId: null,
              offset: null,
              penMac: null,
              mobile: null,
              openId: null,
              serial: null,
            },
            {
              id: 4302,
              name: '10',
              type: null,
              status: null,
              corpId: null,
              offset: null,
              penMac: null,
              mobile: null,
              openId: null,
              serial: null,
            },
            {
              id: 4304,
              name: '12',
              type: null,
              status: null,
              corpId: null,
              offset: null,
              penMac: null,
              mobile: null,
              openId: null,
              serial: null,
            },
            {
              id: 4305,
              name: '13',
              type: null,
              status: null,
              corpId: null,
              offset: null,
              penMac: null,
              mobile: null,
              openId: null,
              serial: null,
            },
            {
              id: 4306,
              name: '14',
              type: null,
              status: null,
              corpId: null,
              offset: null,
              penMac: null,
              mobile: null,
              openId: null,
              serial: null,
            },
            {
              id: 4307,
              name: '15',
              type: null,
              status: null,
              corpId: null,
              offset: null,
              penMac: null,
              mobile: null,
              openId: null,
              serial: null,
            },
            {
              id: 4308,
              name: '16',
              type: null,
              status: null,
              corpId: null,
              offset: null,
              penMac: null,
              mobile: null,
              openId: null,
              serial: null,
            },
            {
              id: 4310,
              name: '18',
              type: null,
              status: null,
              corpId: null,
              offset: null,
              penMac: null,
              mobile: null,
              openId: null,
              serial: null,
            },
            {
              id: 4311,
              name: '19',
              type: null,
              status: null,
              corpId: null,
              offset: null,
              penMac: null,
              mobile: null,
              openId: null,
              serial: null,
            },
            {
              id: 4312,
              name: '20',
              type: null,
              status: null,
              corpId: null,
              offset: null,
              penMac: null,
              mobile: null,
              openId: null,
              serial: null,
            },
            {
              id: 4313,
              name: '21',
              type: null,
              status: null,
              corpId: null,
              offset: null,
              penMac: null,
              mobile: null,
              openId: null,
              serial: null,
            },
            {
              id: 4314,
              name: '22',
              type: null,
              status: null,
              corpId: null,
              offset: null,
              penMac: null,
              mobile: null,
              openId: null,
              serial: null,
            },
            {
              id: 4315,
              name: '23',
              type: null,
              status: null,
              corpId: null,
              offset: null,
              penMac: null,
              mobile: null,
              openId: null,
              serial: null,
            },
            {
              id: 4316,
              name: '24',
              type: null,
              status: null,
              corpId: null,
              offset: null,
              penMac: null,
              mobile: null,
              openId: null,
              serial: null,
            },
            {
              id: 4318,
              name: '26',
              type: null,
              status: null,
              corpId: null,
              offset: null,
              penMac: null,
              mobile: null,
              openId: null,
              serial: null,
            },
            {
              id: 4319,
              name: '27',
              type: null,
              status: null,
              corpId: null,
              offset: null,
              penMac: null,
              mobile: null,
              openId: null,
              serial: null,
            },
            {
              id: 4320,
              name: '28',
              type: null,
              status: null,
              corpId: null,
              offset: null,
              penMac: null,
              mobile: null,
              openId: null,
              serial: null,
            },
            {
              id: 4321,
              name: '29',
              type: null,
              status: null,
              corpId: null,
              offset: null,
              penMac: null,
              mobile: null,
              openId: null,
              serial: null,
            },
            {
              id: 4323,
              name: '31',
              type: null,
              status: null,
              corpId: null,
              offset: null,
              penMac: null,
              mobile: null,
              openId: null,
              serial: null,
            },
            {
              id: 4324,
              name: '32',
              type: null,
              status: null,
              corpId: null,
              offset: null,
              penMac: null,
              mobile: null,
              openId: null,
              serial: null,
            },
            {
              id: 4325,
              name: '33',
              type: null,
              status: null,
              corpId: null,
              offset: null,
              penMac: null,
              mobile: null,
              openId: null,
              serial: null,
            },
            {
              id: 4326,
              name: '34',
              type: null,
              status: null,
              corpId: null,
              offset: null,
              penMac: null,
              mobile: null,
              openId: null,
              serial: null,
            },
            {
              id: 4327,
              name: '35',
              type: null,
              status: null,
              corpId: null,
              offset: null,
              penMac: null,
              mobile: null,
              openId: null,
              serial: null,
            },
          ],
          unJoinUserList: [
            {
              id: 4293,
              name: '1',
              type: null,
              status: null,
              corpId: null,
              offset: null,
              penMac: null,
              mobile: null,
              openId: null,
              serial: null,
            },
            {
              id: 4301,
              name: '9',
              type: null,
              status: null,
              corpId: null,
              offset: null,
              penMac: null,
              mobile: null,
              openId: null,
              serial: null,
            },
            {
              id: 4303,
              name: '11',
              type: null,
              status: null,
              corpId: null,
              offset: null,
              penMac: null,
              mobile: null,
              openId: null,
              serial: null,
            },
            {
              id: 4309,
              name: '17',
              type: null,
              status: null,
              corpId: null,
              offset: null,
              penMac: null,
              mobile: null,
              openId: null,
              serial: null,
            },
            {
              id: 4317,
              name: '25',
              type: null,
              status: null,
              corpId: null,
              offset: null,
              penMac: null,
              mobile: null,
              openId: null,
              serial: null,
            },
            {
              id: 4322,
              name: '30',
              type: null,
              status: null,
              corpId: null,
              offset: null,
              penMac: null,
              mobile: null,
              openId: null,
              serial: null,
            },
          ],
          revisedUserList: null,
          unReviseUserList: null,
          unCorrectUserList: null,
          correctedUserList: null,
          unEvaluateUserList: null,
          evaluatedUserList: null,
          planJoinResultVo: null,
        },
        {
          id: 8952,
          name: '卢湾活动单0508自然',
          code: null,
          planKind: '作业',
          stageKind: '课中',
          dateKind: null,
          beginTime: 1683525600000,
          endTime: 1683536400000,
          serial: null,
          num: 2,
          roomId: 582,
          attributeKind: null,
          attributeId: null,
          planTime: null,
          planBy: null,
          roleKind: null,
          createTime: 1683522551000,
          createBy: 1,
          updateBy: null,
          updateTime: null,
          parentId: null,
          status: '1',
          auditId: null,
          auditStatus: null,
          finishedUser: null,
          totalUser: null,
          totalResource: null,
          progress: null,
          avgTime: null,
          fromId: 1000318,
          fromKind: null,
          flag: null,
          joinStatus: null,
          classRelationList: null,
          resourceRelationList: [
            {
              id: 16697,
              planId: 8952,
              relationId: 2364368931242933,
              relationName: '卢湾活动单0508',
              relationSerial: null,
              relationKind: 'questionSet',
              codeType: '1',
              status: 1,
              width: 210,
              height: 297,
            },
          ],
          classId: 574,
          subjectId: 100,
          pageIds: null,
          joinedUserList: [
            {
              id: 4293,
              name: '1',
              type: null,
              status: null,
              corpId: null,
              offset: null,
              penMac: null,
              mobile: null,
              openId: null,
              serial: null,
            },
            {
              id: 4294,
              name: '2',
              type: null,
              status: null,
              corpId: null,
              offset: null,
              penMac: null,
              mobile: null,
              openId: null,
              serial: null,
            },
            {
              id: 4295,
              name: '3',
              type: null,
              status: null,
              corpId: null,
              offset: null,
              penMac: null,
              mobile: null,
              openId: null,
              serial: null,
            },
            {
              id: 4296,
              name: '4',
              type: null,
              status: null,
              corpId: null,
              offset: null,
              penMac: null,
              mobile: null,
              openId: null,
              serial: null,
            },
            {
              id: 4297,
              name: '5',
              type: null,
              status: null,
              corpId: null,
              offset: null,
              penMac: null,
              mobile: null,
              openId: null,
              serial: null,
            },
            {
              id: 4298,
              name: '6',
              type: null,
              status: null,
              corpId: null,
              offset: null,
              penMac: null,
              mobile: null,
              openId: null,
              serial: null,
            },
            {
              id: 4299,
              name: '7',
              type: null,
              status: null,
              corpId: null,
              offset: null,
              penMac: null,
              mobile: null,
              openId: null,
              serial: null,
            },
            {
              id: 4300,
              name: '8',
              type: null,
              status: null,
              corpId: null,
              offset: null,
              penMac: null,
              mobile: null,
              openId: null,
              serial: null,
            },
            {
              id: 4301,
              name: '9',
              type: null,
              status: null,
              corpId: null,
              offset: null,
              penMac: null,
              mobile: null,
              openId: null,
              serial: null,
            },
            {
              id: 4302,
              name: '10',
              type: null,
              status: null,
              corpId: null,
              offset: null,
              penMac: null,
              mobile: null,
              openId: null,
              serial: null,
            },
            {
              id: 4305,
              name: '13',
              type: null,
              status: null,
              corpId: null,
              offset: null,
              penMac: null,
              mobile: null,
              openId: null,
              serial: null,
            },
            {
              id: 4306,
              name: '14',
              type: null,
              status: null,
              corpId: null,
              offset: null,
              penMac: null,
              mobile: null,
              openId: null,
              serial: null,
            },
            {
              id: 4307,
              name: '15',
              type: null,
              status: null,
              corpId: null,
              offset: null,
              penMac: null,
              mobile: null,
              openId: null,
              serial: null,
            },
            {
              id: 4308,
              name: '16',
              type: null,
              status: null,
              corpId: null,
              offset: null,
              penMac: null,
              mobile: null,
              openId: null,
              serial: null,
            },
            {
              id: 4310,
              name: '18',
              type: null,
              status: null,
              corpId: null,
              offset: null,
              penMac: null,
              mobile: null,
              openId: null,
              serial: null,
            },
            {
              id: 4311,
              name: '19',
              type: null,
              status: null,
              corpId: null,
              offset: null,
              penMac: null,
              mobile: null,
              openId: null,
              serial: null,
            },
            {
              id: 4312,
              name: '20',
              type: null,
              status: null,
              corpId: null,
              offset: null,
              penMac: null,
              mobile: null,
              openId: null,
              serial: null,
            },
            {
              id: 4313,
              name: '21',
              type: null,
              status: null,
              corpId: null,
              offset: null,
              penMac: null,
              mobile: null,
              openId: null,
              serial: null,
            },
            {
              id: 4314,
              name: '22',
              type: null,
              status: null,
              corpId: null,
              offset: null,
              penMac: null,
              mobile: null,
              openId: null,
              serial: null,
            },
            {
              id: 4315,
              name: '23',
              type: null,
              status: null,
              corpId: null,
              offset: null,
              penMac: null,
              mobile: null,
              openId: null,
              serial: null,
            },
            {
              id: 4316,
              name: '24',
              type: null,
              status: null,
              corpId: null,
              offset: null,
              penMac: null,
              mobile: null,
              openId: null,
              serial: null,
            },
            {
              id: 4317,
              name: '25',
              type: null,
              status: null,
              corpId: null,
              offset: null,
              penMac: null,
              mobile: null,
              openId: null,
              serial: null,
            },
            {
              id: 4318,
              name: '26',
              type: null,
              status: null,
              corpId: null,
              offset: null,
              penMac: null,
              mobile: null,
              openId: null,
              serial: null,
            },
            {
              id: 4319,
              name: '27',
              type: null,
              status: null,
              corpId: null,
              offset: null,
              penMac: null,
              mobile: null,
              openId: null,
              serial: null,
            },
            {
              id: 4320,
              name: '28',
              type: null,
              status: null,
              corpId: null,
              offset: null,
              penMac: null,
              mobile: null,
              openId: null,
              serial: null,
            },
            {
              id: 4321,
              name: '29',
              type: null,
              status: null,
              corpId: null,
              offset: null,
              penMac: null,
              mobile: null,
              openId: null,
              serial: null,
            },
            {
              id: 4322,
              name: '30',
              type: null,
              status: null,
              corpId: null,
              offset: null,
              penMac: null,
              mobile: null,
              openId: null,
              serial: null,
            },
            {
              id: 4323,
              name: '31',
              type: null,
              status: null,
              corpId: null,
              offset: null,
              penMac: null,
              mobile: null,
              openId: null,
              serial: null,
            },
            {
              id: 4324,
              name: '32',
              type: null,
              status: null,
              corpId: null,
              offset: null,
              penMac: null,
              mobile: null,
              openId: null,
              serial: null,
            },
            {
              id: 4325,
              name: '33',
              type: null,
              status: null,
              corpId: null,
              offset: null,
              penMac: null,
              mobile: null,
              openId: null,
              serial: null,
            },
            {
              id: 4326,
              name: '34',
              type: null,
              status: null,
              corpId: null,
              offset: null,
              penMac: null,
              mobile: null,
              openId: null,
              serial: null,
            },
            {
              id: 4327,
              name: '35',
              type: null,
              status: null,
              corpId: null,
              offset: null,
              penMac: null,
              mobile: null,
              openId: null,
              serial: null,
            },
          ],
          unJoinUserList: [
            {
              id: 4303,
              name: '11',
              type: null,
              status: null,
              corpId: null,
              offset: null,
              penMac: null,
              mobile: null,
              openId: null,
              serial: null,
            },
            {
              id: 4304,
              name: '12',
              type: null,
              status: null,
              corpId: null,
              offset: null,
              penMac: null,
              mobile: null,
              openId: null,
              serial: null,
            },
            {
              id: 4309,
              name: '17',
              type: null,
              status: null,
              corpId: null,
              offset: null,
              penMac: null,
              mobile: null,
              openId: null,
              serial: null,
            },
          ],
          revisedUserList: null,
          unReviseUserList: null,
          unCorrectUserList: null,
          correctedUserList: null,
          unEvaluateUserList: null,
          evaluatedUserList: null,
          planJoinResultVo: null,
        },
        {
          id: 8919,
          name: '长方体正方体的表面积课练417更新版',
          code: null,
          planKind: '作业',
          stageKind: '课中',
          dateKind: null,
          beginTime: 1681869600000,
          endTime: 1681887600000,
          serial: null,
          num: 1,
          roomId: 576,
          attributeKind: null,
          attributeId: null,
          planTime: null,
          planBy: null,
          roleKind: null,
          createTime: 1681854546000,
          createBy: 1,
          updateBy: null,
          updateTime: null,
          parentId: null,
          status: '1',
          auditId: null,
          auditStatus: null,
          finishedUser: null,
          totalUser: null,
          totalResource: null,
          progress: null,
          avgTime: null,
          fromId: 1000318,
          fromKind: null,
          flag: null,
          joinStatus: null,
          classRelationList: null,
          resourceRelationList: [
            {
              id: 16631,
              planId: 8919,
              relationId: 2364368931242913,
              relationName: '长方体正方体的表面积课练417更新版',
              relationSerial: null,
              relationKind: 'questionSet',
              codeType: '1',
              status: 1,
              width: 210,
              height: 297,
            },
          ],
          classId: 575,
          subjectId: 22,
          pageIds: null,
          joinedUserList: [
            {
              id: 4328,
              name: '程德涵',
              type: null,
              status: null,
              corpId: null,
              offset: null,
              penMac: null,
              mobile: null,
              openId: null,
              serial: null,
            },
            {
              id: 4329,
              name: '冯淑婷',
              type: null,
              status: null,
              corpId: null,
              offset: null,
              penMac: null,
              mobile: null,
              openId: null,
              serial: null,
            },
            {
              id: 4330,
              name: '郭蓓蕾',
              type: null,
              status: null,
              corpId: null,
              offset: null,
              penMac: null,
              mobile: null,
              openId: null,
              serial: null,
            },
            {
              id: 4331,
              name: '姜润玲',
              type: null,
              status: null,
              corpId: null,
              offset: null,
              penMac: null,
              mobile: null,
              openId: null,
              serial: null,
            },
            {
              id: 4332,
              name: '蒋梓瑜',
              type: null,
              status: null,
              corpId: null,
              offset: null,
              penMac: null,
              mobile: null,
              openId: null,
              serial: null,
            },
            {
              id: 4333,
              name: '刘珂妤',
              type: null,
              status: null,
              corpId: null,
              offset: null,
              penMac: null,
              mobile: null,
              openId: null,
              serial: null,
            },
            {
              id: 4334,
              name: '马栎栎',
              type: null,
              status: null,
              corpId: null,
              offset: null,
              penMac: null,
              mobile: null,
              openId: null,
              serial: null,
            },
            {
              id: 4335,
              name: '沈雨昕',
              type: null,
              status: null,
              corpId: null,
              offset: null,
              penMac: null,
              mobile: null,
              openId: null,
              serial: null,
            },
            {
              id: 4336,
              name: '夏婉婷',
              type: null,
              status: null,
              corpId: null,
              offset: null,
              penMac: null,
              mobile: null,
              openId: null,
              serial: null,
            },
            {
              id: 4337,
              name: '徐嘉乐',
              type: null,
              status: null,
              corpId: null,
              offset: null,
              penMac: null,
              mobile: null,
              openId: null,
              serial: null,
            },
            {
              id: 4338,
              name: '徐莺荣',
              type: null,
              status: null,
              corpId: null,
              offset: null,
              penMac: null,
              mobile: null,
              openId: null,
              serial: null,
            },
            {
              id: 4339,
              name: '袁晟珺',
              type: null,
              status: null,
              corpId: null,
              offset: null,
              penMac: null,
              mobile: null,
              openId: null,
              serial: null,
            },
            {
              id: 4340,
              name: '张乃唐',
              type: null,
              status: null,
              corpId: null,
              offset: null,
              penMac: null,
              mobile: null,
              openId: null,
              serial: null,
            },
            {
              id: 4341,
              name: '张馨奕',
              type: null,
              status: null,
              corpId: null,
              offset: null,
              penMac: null,
              mobile: null,
              openId: null,
              serial: null,
            },
            {
              id: 4342,
              name: '朱箐予',
              type: null,
              status: null,
              corpId: null,
              offset: null,
              penMac: null,
              mobile: null,
              openId: null,
              serial: null,
            },
            {
              id: 4343,
              name: '邹绮洛',
              type: null,
              status: null,
              corpId: null,
              offset: null,
              penMac: null,
              mobile: null,
              openId: null,
              serial: null,
            },
            {
              id: 4344,
              name: '曾彦菲',
              type: null,
              status: null,
              corpId: null,
              offset: null,
              penMac: null,
              mobile: null,
              openId: null,
              serial: null,
            },
            {
              id: 4345,
              name: '曹炎彬',
              type: null,
              status: null,
              corpId: null,
              offset: null,
              penMac: null,
              mobile: null,
              openId: null,
              serial: null,
            },
            {
              id: 4346,
              name: '曹致立',
              type: null,
              status: null,
              corpId: null,
              offset: null,
              penMac: null,
              mobile: null,
              openId: null,
              serial: null,
            },
            {
              id: 4347,
              name: '陈家麒',
              type: null,
              status: null,
              corpId: null,
              offset: null,
              penMac: null,
              mobile: null,
              openId: null,
              serial: null,
            },
            {
              id: 4348,
              name: '董逸轩',
              type: null,
              status: null,
              corpId: null,
              offset: null,
              penMac: null,
              mobile: null,
              openId: null,
              serial: null,
            },
            {
              id: 4349,
              name: '龚奕辉',
              type: null,
              status: null,
              corpId: null,
              offset: null,
              penMac: null,
              mobile: null,
              openId: null,
              serial: null,
            },
            {
              id: 4350,
              name: '黄天翊',
              type: null,
              status: null,
              corpId: null,
              offset: null,
              penMac: null,
              mobile: null,
              openId: null,
              serial: null,
            },
            {
              id: 4351,
              name: '梅云清',
              type: null,
              status: null,
              corpId: null,
              offset: null,
              penMac: null,
              mobile: null,
              openId: null,
              serial: null,
            },
            {
              id: 4352,
              name: '戎逸',
              type: null,
              status: null,
              corpId: null,
              offset: null,
              penMac: null,
              mobile: null,
              openId: null,
              serial: null,
            },
            {
              id: 4353,
              name: '沈嘉洋',
              type: null,
              status: null,
              corpId: null,
              offset: null,
              penMac: null,
              mobile: null,
              openId: null,
              serial: null,
            },
            {
              id: 4354,
              name: '孙泽南',
              type: null,
              status: null,
              corpId: null,
              offset: null,
              penMac: null,
              mobile: null,
              openId: null,
              serial: null,
            },
            {
              id: 4355,
              name: '王梓睿',
              type: null,
              status: null,
              corpId: null,
              offset: null,
              penMac: null,
              mobile: null,
              openId: null,
              serial: null,
            },
            {
              id: 4356,
              name: '吴昕淇',
              type: null,
              status: null,
              corpId: null,
              offset: null,
              penMac: null,
              mobile: null,
              openId: null,
              serial: null,
            },
            {
              id: 4357,
              name: '徐梓浩',
              type: null,
              status: null,
              corpId: null,
              offset: null,
              penMac: null,
              mobile: null,
              openId: null,
              serial: null,
            },
            {
              id: 4358,
              name: '袁辰昊',
              type: null,
              status: null,
              corpId: null,
              offset: null,
              penMac: null,
              mobile: null,
              openId: null,
              serial: null,
            },
            {
              id: 4359,
              name: '朱懿轩',
              type: null,
              status: null,
              corpId: null,
              offset: null,
              penMac: null,
              mobile: null,
              openId: null,
              serial: null,
            },
            {
              id: 4360,
              name: '孙翌宸',
              type: null,
              status: null,
              corpId: null,
              offset: null,
              penMac: null,
              mobile: null,
              openId: null,
              serial: null,
            },
            {
              id: 4361,
              name: '杨舒然',
              type: null,
              status: null,
              corpId: null,
              offset: null,
              penMac: null,
              mobile: null,
              openId: null,
              serial: null,
            },
            {
              id: 4362,
              name: '王小娴',
              type: null,
              status: null,
              corpId: null,
              offset: null,
              penMac: null,
              mobile: null,
              openId: null,
              serial: null,
            },
          ],
          unJoinUserList: [],
          revisedUserList: null,
          unReviseUserList: null,
          unCorrectUserList: null,
          correctedUserList: null,
          unEvaluateUserList: null,
          evaluatedUserList: null,
          planJoinResultVo: null,
        },

        {
          id: 8969,
          name: '卢湾活动单0510',
          code: null,
          planKind: '作业',
          stageKind: '课中',
          dateKind: null,
          beginTime: 1683779400000,
          endTime: 1683799200000,
          serial: null,
          num: 1,
          roomId: 582,
          attributeKind: null,
          attributeId: null,
          planTime: null,
          planBy: null,
          roleKind: null,
          createTime: 1683778492000,
          createBy: 4363,
          updateBy: null,
          updateTime: null,
          parentId: null,
          status: '1',
          auditId: null,
          auditStatus: null,
          finishedUser: null,
          totalUser: null,
          totalResource: null,
          progress: null,
          avgTime: null,
          fromId: 1000318,
          fromKind: null,
          flag: null,
          joinStatus: null,
          classRelationList: null,
          resourceRelationList: [
            {
              id: 16731,
              planId: 8969,
              relationId: 2364368931242938,
              relationName: '卢湾活动单0510',
              relationSerial: null,
              relationKind: 'questionSet',
              codeType: '1',
              status: 1,
              width: 210,
              height: 297,
            },
          ],
          classId: 574,
          subjectId: 100,
          pageIds: null,
          joinedUserList: [
            {
              id: 4293,
              name: '1',
              type: null,
              status: null,
              corpId: null,
              offset: null,
              penMac: null,
              mobile: null,
              openId: null,
              serial: null,
            },
            {
              id: 4294,
              name: '2',
              type: null,
              status: null,
              corpId: null,
              offset: null,
              penMac: null,
              mobile: null,
              openId: null,
              serial: null,
            },
            {
              id: 4295,
              name: '3',
              type: null,
              status: null,
              corpId: null,
              offset: null,
              penMac: null,
              mobile: null,
              openId: null,
              serial: null,
            },
            {
              id: 4296,
              name: '4',
              type: null,
              status: null,
              corpId: null,
              offset: null,
              penMac: null,
              mobile: null,
              openId: null,
              serial: null,
            },
            {
              id: 4297,
              name: '5',
              type: null,
              status: null,
              corpId: null,
              offset: null,
              penMac: null,
              mobile: null,
              openId: null,
              serial: null,
            },
            {
              id: 4298,
              name: '6',
              type: null,
              status: null,
              corpId: null,
              offset: null,
              penMac: null,
              mobile: null,
              openId: null,
              serial: null,
            },
            {
              id: 4299,
              name: '7',
              type: null,
              status: null,
              corpId: null,
              offset: null,
              penMac: null,
              mobile: null,
              openId: null,
              serial: null,
            },
            {
              id: 4300,
              name: '8',
              type: null,
              status: null,
              corpId: null,
              offset: null,
              penMac: null,
              mobile: null,
              openId: null,
              serial: null,
            },
            {
              id: 4301,
              name: '9',
              type: null,
              status: null,
              corpId: null,
              offset: null,
              penMac: null,
              mobile: null,
              openId: null,
              serial: null,
            },
            {
              id: 4302,
              name: '10',
              type: null,
              status: null,
              corpId: null,
              offset: null,
              penMac: null,
              mobile: null,
              openId: null,
              serial: null,
            },
            {
              id: 4303,
              name: '11',
              type: null,
              status: null,
              corpId: null,
              offset: null,
              penMac: null,
              mobile: null,
              openId: null,
              serial: null,
            },
            {
              id: 4304,
              name: '12',
              type: null,
              status: null,
              corpId: null,
              offset: null,
              penMac: null,
              mobile: null,
              openId: null,
              serial: null,
            },
            {
              id: 4305,
              name: '13',
              type: null,
              status: null,
              corpId: null,
              offset: null,
              penMac: null,
              mobile: null,
              openId: null,
              serial: null,
            },
            {
              id: 4306,
              name: '14',
              type: null,
              status: null,
              corpId: null,
              offset: null,
              penMac: null,
              mobile: null,
              openId: null,
              serial: null,
            },
            {
              id: 4307,
              name: '15',
              type: null,
              status: null,
              corpId: null,
              offset: null,
              penMac: null,
              mobile: null,
              openId: null,
              serial: null,
            },
            {
              id: 4308,
              name: '16',
              type: null,
              status: null,
              corpId: null,
              offset: null,
              penMac: null,
              mobile: null,
              openId: null,
              serial: null,
            },
            {
              id: 4309,
              name: '17',
              type: null,
              status: null,
              corpId: null,
              offset: null,
              penMac: null,
              mobile: null,
              openId: null,
              serial: null,
            },
            {
              id: 4310,
              name: '18',
              type: null,
              status: null,
              corpId: null,
              offset: null,
              penMac: null,
              mobile: null,
              openId: null,
              serial: null,
            },
            {
              id: 4311,
              name: '19',
              type: null,
              status: null,
              corpId: null,
              offset: null,
              penMac: null,
              mobile: null,
              openId: null,
              serial: null,
            },
            {
              id: 4312,
              name: '20',
              type: null,
              status: null,
              corpId: null,
              offset: null,
              penMac: null,
              mobile: null,
              openId: null,
              serial: null,
            },
            {
              id: 4313,
              name: '21',
              type: null,
              status: null,
              corpId: null,
              offset: null,
              penMac: null,
              mobile: null,
              openId: null,
              serial: null,
            },
            {
              id: 4314,
              name: '22',
              type: null,
              status: null,
              corpId: null,
              offset: null,
              penMac: null,
              mobile: null,
              openId: null,
              serial: null,
            },
            {
              id: 4315,
              name: '23',
              type: null,
              status: null,
              corpId: null,
              offset: null,
              penMac: null,
              mobile: null,
              openId: null,
              serial: null,
            },
            {
              id: 4316,
              name: '24',
              type: null,
              status: null,
              corpId: null,
              offset: null,
              penMac: null,
              mobile: null,
              openId: null,
              serial: null,
            },
            {
              id: 4317,
              name: '25',
              type: null,
              status: null,
              corpId: null,
              offset: null,
              penMac: null,
              mobile: null,
              openId: null,
              serial: null,
            },
            {
              id: 4318,
              name: '26',
              type: null,
              status: null,
              corpId: null,
              offset: null,
              penMac: null,
              mobile: null,
              openId: null,
              serial: null,
            },
            {
              id: 4319,
              name: '27',
              type: null,
              status: null,
              corpId: null,
              offset: null,
              penMac: null,
              mobile: null,
              openId: null,
              serial: null,
            },
            {
              id: 4320,
              name: '28',
              type: null,
              status: null,
              corpId: null,
              offset: null,
              penMac: null,
              mobile: null,
              openId: null,
              serial: null,
            },
            {
              id: 4321,
              name: '29',
              type: null,
              status: null,
              corpId: null,
              offset: null,
              penMac: null,
              mobile: null,
              openId: null,
              serial: null,
            },
            {
              id: 4322,
              name: '30',
              type: null,
              status: null,
              corpId: null,
              offset: null,
              penMac: null,
              mobile: null,
              openId: null,
              serial: null,
            },
            {
              id: 4323,
              name: '31',
              type: null,
              status: null,
              corpId: null,
              offset: null,
              penMac: null,
              mobile: null,
              openId: null,
              serial: null,
            },
            {
              id: 4324,
              name: '32',
              type: null,
              status: null,
              corpId: null,
              offset: null,
              penMac: null,
              mobile: null,
              openId: null,
              serial: null,
            },
            {
              id: 4325,
              name: '33',
              type: null,
              status: null,
              corpId: null,
              offset: null,
              penMac: null,
              mobile: null,
              openId: null,
              serial: null,
            },
            {
              id: 4326,
              name: '34',
              type: null,
              status: null,
              corpId: null,
              offset: null,
              penMac: null,
              mobile: null,
              openId: null,
              serial: null,
            },
            {
              id: 4327,
              name: '35',
              type: null,
              status: null,
              corpId: null,
              offset: null,
              penMac: null,
              mobile: null,
              openId: null,
              serial: null,
            },
          ],
          unJoinUserList: [],
          revisedUserList: null,
          unReviseUserList: null,
          unCorrectUserList: null,
          correctedUserList: null,
          unEvaluateUserList: null,
          evaluatedUserList: null,
          planJoinResultVo: null,
        },
        {
          id: 8975,
          name: '卢湾-长方体正方体的表面积课练卷修改版0515',
          code: null,
          planKind: '作业',
          stageKind: '课中',
          dateKind: null,
          beginTime: 1684211400000,
          endTime: 1684231200000,
          serial: null,
          num: 1,
          roomId: 582,
          attributeKind: null,
          attributeId: null,
          planTime: null,
          planBy: null,
          roleKind: null,
          createTime: 1684199157000,
          createBy: 4363,
          updateBy: null,
          updateTime: null,
          parentId: null,
          status: '1',
          auditId: null,
          auditStatus: null,
          finishedUser: null,
          totalUser: null,
          totalResource: null,
          progress: null,
          avgTime: null,
          fromId: 1000318,
          fromKind: null,
          flag: null,
          joinStatus: null,
          classRelationList: null,
          resourceRelationList: [
            {
              id: 16743,
              planId: 8975,
              relationId: 2364368931242939,
              relationName: '卢湾-长方体正方体的表面积课练卷修改版0515',
              relationSerial: null,
              relationKind: 'questionSet',
              codeType: '1',
              status: 1,
              width: 210,
              height: 297,
            },
          ],
          classId: 574,
          subjectId: 22,
          pageIds: null,
          joinedUserList: [
            {
              id: 4293,
              name: '1',
              type: null,
              status: null,
              corpId: null,
              offset: null,
              penMac: null,
              mobile: null,
              openId: null,
              serial: null,
            },
            {
              id: 4294,
              name: '2',
              type: null,
              status: null,
              corpId: null,
              offset: null,
              penMac: null,
              mobile: null,
              openId: null,
              serial: null,
            },
            {
              id: 4295,
              name: '3',
              type: null,
              status: null,
              corpId: null,
              offset: null,
              penMac: null,
              mobile: null,
              openId: null,
              serial: null,
            },
            {
              id: 4296,
              name: '4',
              type: null,
              status: null,
              corpId: null,
              offset: null,
              penMac: null,
              mobile: null,
              openId: null,
              serial: null,
            },
            {
              id: 4297,
              name: '5',
              type: null,
              status: null,
              corpId: null,
              offset: null,
              penMac: null,
              mobile: null,
              openId: null,
              serial: null,
            },
            {
              id: 4298,
              name: '6',
              type: null,
              status: null,
              corpId: null,
              offset: null,
              penMac: null,
              mobile: null,
              openId: null,
              serial: null,
            },
            {
              id: 4299,
              name: '7',
              type: null,
              status: null,
              corpId: null,
              offset: null,
              penMac: null,
              mobile: null,
              openId: null,
              serial: null,
            },
            {
              id: 4300,
              name: '8',
              type: null,
              status: null,
              corpId: null,
              offset: null,
              penMac: null,
              mobile: null,
              openId: null,
              serial: null,
            },
            {
              id: 4301,
              name: '9',
              type: null,
              status: null,
              corpId: null,
              offset: null,
              penMac: null,
              mobile: null,
              openId: null,
              serial: null,
            },
            {
              id: 4302,
              name: '10',
              type: null,
              status: null,
              corpId: null,
              offset: null,
              penMac: null,
              mobile: null,
              openId: null,
              serial: null,
            },
            {
              id: 4303,
              name: '11',
              type: null,
              status: null,
              corpId: null,
              offset: null,
              penMac: null,
              mobile: null,
              openId: null,
              serial: null,
            },
            {
              id: 4304,
              name: '12',
              type: null,
              status: null,
              corpId: null,
              offset: null,
              penMac: null,
              mobile: null,
              openId: null,
              serial: null,
            },
            {
              id: 4305,
              name: '13',
              type: null,
              status: null,
              corpId: null,
              offset: null,
              penMac: null,
              mobile: null,
              openId: null,
              serial: null,
            },
            {
              id: 4306,
              name: '14',
              type: null,
              status: null,
              corpId: null,
              offset: null,
              penMac: null,
              mobile: null,
              openId: null,
              serial: null,
            },
            {
              id: 4307,
              name: '15',
              type: null,
              status: null,
              corpId: null,
              offset: null,
              penMac: null,
              mobile: null,
              openId: null,
              serial: null,
            },
            {
              id: 4308,
              name: '16',
              type: null,
              status: null,
              corpId: null,
              offset: null,
              penMac: null,
              mobile: null,
              openId: null,
              serial: null,
            },
            {
              id: 4310,
              name: '18',
              type: null,
              status: null,
              corpId: null,
              offset: null,
              penMac: null,
              mobile: null,
              openId: null,
              serial: null,
            },
            {
              id: 4311,
              name: '19',
              type: null,
              status: null,
              corpId: null,
              offset: null,
              penMac: null,
              mobile: null,
              openId: null,
              serial: null,
            },
            {
              id: 4312,
              name: '20',
              type: null,
              status: null,
              corpId: null,
              offset: null,
              penMac: null,
              mobile: null,
              openId: null,
              serial: null,
            },
            {
              id: 4313,
              name: '21',
              type: null,
              status: null,
              corpId: null,
              offset: null,
              penMac: null,
              mobile: null,
              openId: null,
              serial: null,
            },
            {
              id: 4314,
              name: '22',
              type: null,
              status: null,
              corpId: null,
              offset: null,
              penMac: null,
              mobile: null,
              openId: null,
              serial: null,
            },
            {
              id: 4315,
              name: '23',
              type: null,
              status: null,
              corpId: null,
              offset: null,
              penMac: null,
              mobile: null,
              openId: null,
              serial: null,
            },
            {
              id: 4317,
              name: '25',
              type: null,
              status: null,
              corpId: null,
              offset: null,
              penMac: null,
              mobile: null,
              openId: null,
              serial: null,
            },
            {
              id: 4318,
              name: '26',
              type: null,
              status: null,
              corpId: null,
              offset: null,
              penMac: null,
              mobile: null,
              openId: null,
              serial: null,
            },
            {
              id: 4320,
              name: '28',
              type: null,
              status: null,
              corpId: null,
              offset: null,
              penMac: null,
              mobile: null,
              openId: null,
              serial: null,
            },
            {
              id: 4321,
              name: '29',
              type: null,
              status: null,
              corpId: null,
              offset: null,
              penMac: null,
              mobile: null,
              openId: null,
              serial: null,
            },
            {
              id: 4322,
              name: '30',
              type: null,
              status: null,
              corpId: null,
              offset: null,
              penMac: null,
              mobile: null,
              openId: null,
              serial: null,
            },
            {
              id: 4323,
              name: '31',
              type: null,
              status: null,
              corpId: null,
              offset: null,
              penMac: null,
              mobile: null,
              openId: null,
              serial: null,
            },
            {
              id: 4324,
              name: '32',
              type: null,
              status: null,
              corpId: null,
              offset: null,
              penMac: null,
              mobile: null,
              openId: null,
              serial: null,
            },
            {
              id: 4325,
              name: '33',
              type: null,
              status: null,
              corpId: null,
              offset: null,
              penMac: null,
              mobile: null,
              openId: null,
              serial: null,
            },
            {
              id: 4326,
              name: '34',
              type: null,
              status: null,
              corpId: null,
              offset: null,
              penMac: null,
              mobile: null,
              openId: null,
              serial: null,
            },
            {
              id: 4327,
              name: '35',
              type: null,
              status: null,
              corpId: null,
              offset: null,
              penMac: null,
              mobile: null,
              openId: null,
              serial: null,
            },
          ],
          unJoinUserList: [
            {
              id: 4309,
              name: '17',
              type: null,
              status: null,
              corpId: null,
              offset: null,
              penMac: null,
              mobile: null,
              openId: null,
              serial: null,
            },
            {
              id: 4316,
              name: '24',
              type: null,
              status: null,
              corpId: null,
              offset: null,
              penMac: null,
              mobile: null,
              openId: null,
              serial: null,
            },
            {
              id: 4319,
              name: '27',
              type: null,
              status: null,
              corpId: null,
              offset: null,
              penMac: null,
              mobile: null,
              openId: null,
              serial: null,
            },
          ],
          revisedUserList: null,
          unReviseUserList: null,
          unCorrectUserList: null,
          correctedUserList: null,
          unEvaluateUserList: null,
          evaluatedUserList: null,
          planJoinResultVo: null,
        },
        {
          id: 9129,
          name: '长方体表面积应用-课练卷529',
          code: null,
          planKind: '作业',
          stageKind: '课中',
          dateKind: null,
          beginTime: 1685422833000,
          endTime: 1685437236000,
          serial: null,
          num: 0,
          roomId: 582,
          attributeKind: null,
          attributeId: null,
          planTime: null,
          planBy: null,
          roleKind: null,
          createTime: 1685408042000,
          createBy: 4363,
          updateBy: null,
          updateTime: null,
          parentId: null,
          status: '1',
          auditId: null,
          auditStatus: null,
          finishedUser: null,
          totalUser: null,
          totalResource: null,
          progress: null,
          avgTime: null,
          fromId: 1000318,
          fromKind: null,
          flag: null,
          joinStatus: null,
          classRelationList: null,
          resourceRelationList: [
            {
              id: 17051,
              planId: 9129,
              relationId: 2364368931242997,
              relationName: '长方体表面积应用-课练卷529',
              relationSerial: null,
              relationKind: 'questionSet',
              codeType: '1',
              status: 1,
              width: 210,
              height: 297,
            },
          ],
          classId: 574,
          subjectId: 22,
          pageIds: null,
          joinedUserList: [
            {
              id: 4293,
              name: '1',
              type: null,
              status: null,
              corpId: null,
              offset: null,
              penMac: null,
              mobile: null,
              openId: null,
              serial: null,
            },
            {
              id: 4294,
              name: '2',
              type: null,
              status: null,
              corpId: null,
              offset: null,
              penMac: null,
              mobile: null,
              openId: null,
              serial: null,
            },
            {
              id: 4295,
              name: '3',
              type: null,
              status: null,
              corpId: null,
              offset: null,
              penMac: null,
              mobile: null,
              openId: null,
              serial: null,
            },
            {
              id: 4296,
              name: '4',
              type: null,
              status: null,
              corpId: null,
              offset: null,
              penMac: null,
              mobile: null,
              openId: null,
              serial: null,
            },
            {
              id: 4297,
              name: '5',
              type: null,
              status: null,
              corpId: null,
              offset: null,
              penMac: null,
              mobile: null,
              openId: null,
              serial: null,
            },
            {
              id: 4298,
              name: '6',
              type: null,
              status: null,
              corpId: null,
              offset: null,
              penMac: null,
              mobile: null,
              openId: null,
              serial: null,
            },
            {
              id: 4299,
              name: '7',
              type: null,
              status: null,
              corpId: null,
              offset: null,
              penMac: null,
              mobile: null,
              openId: null,
              serial: null,
            },
            {
              id: 4301,
              name: '9',
              type: null,
              status: null,
              corpId: null,
              offset: null,
              penMac: null,
              mobile: null,
              openId: null,
              serial: null,
            },
            {
              id: 4302,
              name: '10',
              type: null,
              status: null,
              corpId: null,
              offset: null,
              penMac: null,
              mobile: null,
              openId: null,
              serial: null,
            },
            {
              id: 4303,
              name: '11',
              type: null,
              status: null,
              corpId: null,
              offset: null,
              penMac: null,
              mobile: null,
              openId: null,
              serial: null,
            },
            {
              id: 4304,
              name: '12',
              type: null,
              status: null,
              corpId: null,
              offset: null,
              penMac: null,
              mobile: null,
              openId: null,
              serial: null,
            },
            {
              id: 4305,
              name: '13',
              type: null,
              status: null,
              corpId: null,
              offset: null,
              penMac: null,
              mobile: null,
              openId: null,
              serial: null,
            },
            {
              id: 4306,
              name: '14',
              type: null,
              status: null,
              corpId: null,
              offset: null,
              penMac: null,
              mobile: null,
              openId: null,
              serial: null,
            },
            {
              id: 4307,
              name: '15',
              type: null,
              status: null,
              corpId: null,
              offset: null,
              penMac: null,
              mobile: null,
              openId: null,
              serial: null,
            },
            {
              id: 4308,
              name: '16',
              type: null,
              status: null,
              corpId: null,
              offset: null,
              penMac: null,
              mobile: null,
              openId: null,
              serial: null,
            },
            {
              id: 4309,
              name: '17',
              type: null,
              status: null,
              corpId: null,
              offset: null,
              penMac: null,
              mobile: null,
              openId: null,
              serial: null,
            },
            {
              id: 4310,
              name: '18',
              type: null,
              status: null,
              corpId: null,
              offset: null,
              penMac: null,
              mobile: null,
              openId: null,
              serial: null,
            },
            {
              id: 4311,
              name: '19',
              type: null,
              status: null,
              corpId: null,
              offset: null,
              penMac: null,
              mobile: null,
              openId: null,
              serial: null,
            },
            {
              id: 4312,
              name: '20',
              type: null,
              status: null,
              corpId: null,
              offset: null,
              penMac: null,
              mobile: null,
              openId: null,
              serial: null,
            },
            {
              id: 4313,
              name: '21',
              type: null,
              status: null,
              corpId: null,
              offset: null,
              penMac: null,
              mobile: null,
              openId: null,
              serial: null,
            },
            {
              id: 4314,
              name: '22',
              type: null,
              status: null,
              corpId: null,
              offset: null,
              penMac: null,
              mobile: null,
              openId: null,
              serial: null,
            },
            {
              id: 4315,
              name: '23',
              type: null,
              status: null,
              corpId: null,
              offset: null,
              penMac: null,
              mobile: null,
              openId: null,
              serial: null,
            },
            {
              id: 4316,
              name: '24',
              type: null,
              status: null,
              corpId: null,
              offset: null,
              penMac: null,
              mobile: null,
              openId: null,
              serial: null,
            },
            {
              id: 4317,
              name: '25',
              type: null,
              status: null,
              corpId: null,
              offset: null,
              penMac: null,
              mobile: null,
              openId: null,
              serial: null,
            },
            {
              id: 4318,
              name: '26',
              type: null,
              status: null,
              corpId: null,
              offset: null,
              penMac: null,
              mobile: null,
              openId: null,
              serial: null,
            },
            {
              id: 4319,
              name: '27',
              type: null,
              status: null,
              corpId: null,
              offset: null,
              penMac: null,
              mobile: null,
              openId: null,
              serial: null,
            },
            {
              id: 4321,
              name: '29',
              type: null,
              status: null,
              corpId: null,
              offset: null,
              penMac: null,
              mobile: null,
              openId: null,
              serial: null,
            },
            {
              id: 4322,
              name: '30',
              type: null,
              status: null,
              corpId: null,
              offset: null,
              penMac: null,
              mobile: null,
              openId: null,
              serial: null,
            },
            {
              id: 4323,
              name: '31',
              type: null,
              status: null,
              corpId: null,
              offset: null,
              penMac: null,
              mobile: null,
              openId: null,
              serial: null,
            },
            {
              id: 4324,
              name: '32',
              type: null,
              status: null,
              corpId: null,
              offset: null,
              penMac: null,
              mobile: null,
              openId: null,
              serial: null,
            },
            {
              id: 4325,
              name: '33',
              type: null,
              status: null,
              corpId: null,
              offset: null,
              penMac: null,
              mobile: null,
              openId: null,
              serial: null,
            },
            {
              id: 4326,
              name: '34',
              type: null,
              status: null,
              corpId: null,
              offset: null,
              penMac: null,
              mobile: null,
              openId: null,
              serial: null,
            },
            {
              id: 4327,
              name: '35',
              type: null,
              status: null,
              corpId: null,
              offset: null,
              penMac: null,
              mobile: null,
              openId: null,
              serial: null,
            },
          ],
          unJoinUserList: [
            {
              id: 4300,
              name: '8',
              type: null,
              status: null,
              corpId: null,
              offset: null,
              penMac: null,
              mobile: null,
              openId: null,
              serial: null,
            },
            {
              id: 4320,
              name: '28',
              type: null,
              status: null,
              corpId: null,
              offset: null,
              penMac: null,
              mobile: null,
              openId: null,
              serial: null,
            },
          ],
          revisedUserList: null,
          unReviseUserList: null,
          unCorrectUserList: null,
          correctedUserList: null,
          unEvaluateUserList: null,
          evaluatedUserList: null,
          planJoinResultVo: null,
        },
      ];
      //去掉这几个对象
      // let removeId = [9135, 9018, 8976, 8932, 9077, 9120, 9116, 9014, 8936];
      // var arr = eightData.filter(function(item) {
      //   return removeId.indexOf(item.id) == -1;
      // });

      //保存点击的数组序号
      if (!JSON.parse(localStorage.getItem('ClickNum'))) {
        //第一次进入
        let clickNumList = [];
        clickNumList.push(d.dataIndex);
        localStorage.setItem('ClickNum', JSON.stringify(clickNumList));
        plan_id = arr[0].id;
        start_time = arr[0].beginTime;
        end_time = arr[0].endTime;
        data = arr[0];
      } else {
        //正常 点的一样的序号   如果课前课后点的序号一样（再说）
        let clickNumList = JSON.parse(localStorage.getItem('ClickNum'));
        // 如果点的序号一样  找到这个序号的索引就是ClickExplainNum  最多点9下  因为ClickExplainNum要=0了
        let index = clickNumList.findIndex((item) => item == d.dataIndex);

        if (index != -1) {
          //找到了 把索引给它
          plan_id = arr[index].id;
          start_time = arr[index].beginTime;
          end_time = arr[index].endTime;
          data = arr[index];
        } else {
          //没找到正常
          if (clickNumList.length >= 8) {
            //已经点了9下  重新开
            plan_id = arr[0].id;
            start_time = arr[0].beginTime;
            end_time = arr[0].endTime;
            data = arr[0];
            localStorage.removeItem('ClickNum');
            let clickNumList = [];
            clickNumList.push(d.dataIndex);
            localStorage.setItem('ClickNum', JSON.stringify(clickNumList));
          } else {
            plan_id = arr[clickNumList.length].id;
            start_time = arr[clickNumList.length].beginTime;
            end_time = arr[clickNumList.length].endTime;
            data = arr[clickNumList.length];
            clickNumList.push(d.dataIndex);
            clickNumList = localStorage.setItem(
              'ClickNum',
              JSON.stringify(clickNumList)
            );
          }
        }
      }

      // if (
      ///// !localStorage.getItem('ClickExplainNum') ||
      //       localStorage.getItem('ClickExplainNum') == 9
      //     ) {
      //       //第一次进 没点过讲解 或 已经点了九次
      //       let clickNum = 0;
      //       localStorage.setItem('ClickExplainNum', clickNum);
      //     } else {
      //       //正常追加  -- 如果在同一页面 又点回去了  要去个重复
      //       let clickNum = localStorage.getItem('ClickExplainNum');
      //       clickNum = clickNum + 1;
      //       localStorage.setItem('ClickExplainNum', clickNum);
      //     }

      //在跳转之前保存题集的信息  --  url
      that.getPlanData(data);
      queryPlanById({ id: plan_id }).then((resWorkInfo) => {
        //点击作业获取作业的章节id
        that.getClickHomeworkGetChapter(
          resWorkInfo.data.result.resourceRelationList[1].relationId
        );
      });

      localStorage.setItem('teachanswertype', JSON.stringify(this.answertype));
      getsetId({
        plan_id: plan_id,
      }).then((response) => {
        localStorage.setItem('teachsetId', JSON.stringify(response.data));
        localStorage.setItem('teachplan_id', JSON.stringify(plan_id));
        localStorage.setItem(
          'teachplanIdPlankind',
          JSON.stringify(data.planKind)
        );
        localStorage.setItem('teachclass_id', JSON.stringify(data.classId));
        //
        localStorage.setItem('teachplanIdName', JSON.stringify(data.name));
        localStorage.setItem(
          'teachQuestionSetInformation',
          JSON.stringify(data)
        );
        localStorage.setItem('teachstart_time', JSON.stringify(start_time));
        localStorage.setItem('teachend_time', JSON.stringify(end_time));
        localStorage.setItem('teachStudy_stu_id', JSON.stringify(plan_id));
        localStorage.setItem(
          'teachrelation_kind',
          JSON.stringify('questionSet')
        );
        localStorage.setItem('teachplanIdType', JSON.stringify(data.stageKind));
        localStorage.setItem(
          'teachexplain',
          show ? JSON.stringify(show) : JSON.stringify(false)
        );
        if (
          url.indexOf('explain') != -1 ||
          url.indexOf('Explain') != -1 ||
          url.indexOf('study') != -1 ||
          url.indexOf('Study') != -1 ||
          url.indexOf('correct') != -1 ||
          url.indexOf('Correct') != -1 ||
          url.indexOf('ModifyTheTitle') != -1 ||
          url.indexOf('modifyTheTitle') != -1
        ) {
          if (data.planKind == '测验') {
            url = url + '3';
          } else if (data.planKind == '作业') {
            if (data.stageKind == '课中') {
              url = url + '1';
            } else if (data.stageKind == '课后') {
              url = url + '2';
            }
          }
        }
        this.$router.push({
          name: '' + url,
          params: {
            plan_id,
            start_time,
            end_time,
            data,
          },
        });
      });
    },
    go_to2(plan_id, start_time, end_time, url, data) {
      this.dialogVisible = true;
      this.go_todata1 = plan_id;
      this.go_todata2 = start_time;
      this.go_todata3 = end_time;
      this.go_todata4 = url;
      this.go_todata5 = data;
    },
    ...mapMutations(['getClickHomeworkGetChapter', 'getPlanData']),
  },
};
</script>

<style scoped="scoped" lang="scss">
.testCahatgptShow {
  width: 40px; //图片外面div
  z-index: 99;

  img {
    display: block;
    height: 40px;
  }
}

.testCahatgpt {
  //他俩的框
  display: flex;
  align-items: center;
  z-index: 98;

  position: absolute; //绝对定位给他俩定右边
  bottom: 10px;
  right: 10px;
  height: 500px; //给个高度  不给宽度，等着宽度被组件的300px撑开
}
</style>
