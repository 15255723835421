<template>
	<div class="chat-box">
		<header>
			<span>教学助手</span>
		</header>
		<div class="msg-box" ref="msg-box">

			<div v-for="(item, index) in list" :key="index" class="answer" style="word-wrap:break-word ">
				<div :class="item.userId == 1 ? 'userStyle' : 'backgroundStyle'" style="white-space: pre-wrap;" v-html="item.contentText"></div>
				<el-tag type="warning" v-show="item.userId != 1 ">本回答仅供参考，需自行斟酌</el-tag>
			</div>
		</div>
		<div class="input-box">
			<input type="text" ref="sendMsg" v-model="contentText" @keyup.enter="sendText()" />
			<button class="btn" :class="{ ['btn-active']: contentText }" @click="sendText()"
				:disabled="disabled">发送</button>
		</div>
	</div>
</template>
   
<script>

// import axios from 'axios'
// import {
// 	getChatAis
// } from '../../api/Explain.js';
import {getChatAis} from '../../api/base'
export default {
	data() {
		return {
			ws: null,
			count: 0,
			userId: 1, //当前用户ID
			list: [], //聊天记录的数组
			contentText: "",//input输入的值
			disabled: false,
		};
	},
	created() {
	},
	mounted() {
		// this.initWebSocket();
	},
	methods: {
		getChatAi_(paramss) {
			let _this = this;
			// axios.post('xxxxxxxx',paramss).then((res)=>{
			
			// })
			getChatAis(paramss).then((response) => {
				//转码
				response.data=response.data.replace(/</g, '&lt')
				response.data=response.data.replace(/>/g, '&gt')
				console.log(response.data);
				let data = {
					userId: 0,
					contentText: response.data,
				}
				_this.list.push(data)
				_this.disabled = false
				
				setTimeout(() => {
					_this.scrollBottm();
				}, 200);

			})
		},
		//滚动到底部
		scrollBottm() {
			let el = this.$refs["msg-box"];
			if(el.scrollHeight){
				el.scrollTop = el.scrollHeight;
			}
		
		},
		//发送聊天信息
		sendText() {
			let _this = this;
			if (!_this.contentText) {
				return;
			}
			_this.disabled = true
			_this.$refs["sendMsg"].focus();
			
			let data = {
				userId: 1,
				contentText: _this.contentText,
			}
			_this.list.push(data)
			let params = {
				userId: 1,
				socre: 1,
				question: _this.contentText,
				question_type: "Q&A"
			};
			// _this.ws.send(JSON.stringify(params)); //调用WebSocket send()发送信息的方法
			_this.getChatAi_(params)

			_this.contentText = "";
			_this.timeEnd = Date.parse(new Date(Date.now()));				//发送完的时间
			setTimeout(() => {
				_this.scrollBottm();
			}, 500);

		},
	}
};
</script>
   
<style lang="scss" scoped>
.answer {
	margin: 10px 5px;
	overflow: hidden;
}
.el-tag{
	font-size: 10px;
     display: inline-block;
     transform: scale(0.7);
	padding: 2px 0;
}
.userStyle {
	// word-wrap:break-word;
width: 60%;
	padding: 10px;
	float: right;
	background-color: #409eff;
	color: #fff;
	border-radius: 16px;
	position: relative;
	border-right: 3px solid rgb(47, 224, 255);
}

// .userStyle:after {
// 	width: 10px;
// 	height: 40px;
// 	border-color: #000;
// 	position: absolute;
// 	right: 0;
// 	margin-top: -10px;
// 	content: "";
// }

.backgroundStyle {
	text-align: left;
	border-left: 3px solid rgb(47, 224, 255);
	padding: 10px;
	float: left;
	background-color: #ccc;
	color: #000;
	border-radius: 16px;
}

.chat-box {
	right: 0;
	margin: 0 auto;
	background: #fafafa;
	// position: absolute;
	height: 100%;
	width: 100%;
	max-width: 700px;

	header {
		height: 10%;
		width: 100%;
		background: #19409E;
		display: flex;
		justify-content: center;
		align-items: center;
		font-weight: bold;
		color: white;
	}

	.msg-box {
	
		height: 80%;
		width: 100%;
		overflow-y: auto;
		overflow-y: scroll;
	}

	.input-box {
		padding: 0 0.5rem;

		bottom: 0;
		width: 100%;
		height: 10%;
		background: #fafafa;
		box-shadow: 0 0 5px #ccc;
		display: flex;
		justify-content: space-between;
		align-items: center;

		input {
			height: 2.3rem;
			display: inline-block;
			width: 100%;
			padding: 0.5rem;
			border: none;
			border-radius: 0.2rem;
			font-size: 0.88rem;
		}

		.btn {
			height: 2.3rem;
			min-width: 4rem;
			background: #e0e0e0;
			padding: 0.5rem;
			font-size: 0.88rem;
			color: white;
			text-align: center;
			border-radius: 0.2rem;
			margin-left: 0.5rem;
			transition: 0.5s;
		}

		.btn-active {
			background: #409eff;
		}
	}
}
</style>